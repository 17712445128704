import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Job, OutletContext } from '@typings';

import { JobDetailsContent, JobDetailsStatuses } from '@components/Job';

type Props = {
  job: Job.Model;
};

export const JobObservabilityPage = ({ job: { statuses } }: Props) => {
  const { setTabTitle } = useOutletContext<OutletContext.Job>();

  useEffect(() => {
    setTabTitle(Job.Tab.Observability);
  }, [setTabTitle]);

  return (
    <JobDetailsContent>
      <JobDetailsStatuses statuses={statuses} />
    </JobDetailsContent>
  );
};
