import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Mode, VolumeType } from '@typings';
import { createJob } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  userSelector,
} from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import {
  as,
  formatModelName,
  isNumber,
  normalizeFormErrors,
  path,
  toastifyResponseError,
} from '@utils';
import { jobNavigator } from '@content';

import { Button, Field, Helmet, Modal, Theme } from '@components';
import {
  JobConstructorEnvironments,
  JobConstructorMetadaTags,
  JobConstructorNavigator,
  JobConstructorResources,
  JobConstructorSection,
  JobImageField,
  JobMlflowField,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { Logo } from '@components/Ui';

type Schema = z.infer<ReturnType<typeof makeSchema>>;

type EnvironmentAccumulator = {
  secrets: { [key: string]: string };
  vars: { [key: string]: string };
};

type SchemaParams = {
  clusterName: string;
};

const makeSchema = ({ clusterName }: SchemaParams) =>
  z.object({
    image: z
      .string()
      .min(1)
      .refine(
        (image) => {
          const IMAGE_PREFIX = 'image:';

          if (!image.startsWith(`${IMAGE_PREFIX}//`)) {
            return true;
          }

          /**
           * Image `image://` must contain cluster name
           */
          return image.startsWith(`${IMAGE_PREFIX}//${clusterName}/`);
        },
        {
          message: `Image must be within cluster "${clusterName}"`,
        },
      ),
    entrypoint: z.string(),
    command: z.string(),
    workingDirectory: z.string(),
    presetName: z.string().min(1),
    httpPort: z.coerce.number(),
    httpAuth: z.boolean(),
    name: z.string(),
    description: z.string(),
    tags: z.array(z.string()),
    restartPolicy: z.string(),
    scheduleTimeout: z.coerce.number(),
    priority: z.string(),
    waitForJobs: z.boolean(),
    lifespan: z.string(),
    extendedSharedMemory: z.boolean(),
    privilegedMode: z.boolean(),
    ttyAllocation: z.boolean(),
    mlflow: z.string().optional(),
    passConfig: z.string().optional(),
    envs: z
      .object({
        name: z.string(),
        value: z.string(),
        type: z.enum(['secret', 'variable']),
      })
      .array(),
    volumes: z
      .object({
        name: z.string().optional(),
        prefix: z.string().optional(),
        path: z.string(),
        resource: z.string(),
        type: z.nativeEnum(VolumeType),
        mode: z.nativeEnum(Mode),
      })
      .array(),
  });

export const JobConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);
  const { username } = useSelector(userSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(makeSchema({ clusterName: clusterName! })),
    defaultValues: {
      lifespan: '1d',
      httpAuth: true,
      httpPort: 80,
      envs: [],
      volumes: [
        {
          type: VolumeType.Storage,
          path: '',
          resource: '',
          mode: Mode.ReadOnly,
        },
      ],
    },
  });
  const { control, register, watch, formState, handleSubmit } = methods;

  const [loading, setLoading] = useState(false);

  const { registryUrl } = as.c(cluster);
  const httpPortWatcher = watch('httpPort');
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const processImage = (image: string) => {
    const IMAGE_PREFIX = 'image:';
    const normalizedRegistryUrl = registryUrl.replace(/https?:\/\//g, '');
    const formattedImage = image.replace(IMAGE_PREFIX, '');
    let normalizedImage = '';

    /**
     * Prevent image processing
     *
     * Example: "foo/bar/image-name"
     */
    if (!image.startsWith(IMAGE_PREFIX)) {
      return image;
    }

    /**
     * Example: "image://cluster-name/image-name:tag"
     */
    if (formattedImage.startsWith('//')) {
      normalizedImage = formattedImage.replace(`//${clusterName}/`, '');
    } else if (formattedImage.startsWith('/')) {
      /**
       * Short URI with username
       *
       * Example: "image:/andriy/image-name:tag"
       */
      normalizedImage = path.create(
        organizationName,
        formattedImage.replace('/', ''),
        { prefix: '' },
      );
    } else {
      /**
       * Short URI
       *
       * Example: "image:image-name:tag"
       */
      normalizedImage = path.create(
        organizationName,
        username,
        formattedImage,
        { prefix: '' },
      );
    }

    if (!formattedImage.includes(':')) {
      normalizedImage += ':latest';
    }

    return path.create(normalizedRegistryUrl, normalizedImage, { prefix: '' });
  };

  const handleJobCreate = handleSubmit(
    async ({
      name,
      description,
      command,
      entrypoint,
      extendedSharedMemory,
      httpAuth,
      httpPort,
      image,
      lifespan,
      presetName,
      priority,
      privilegedMode,
      restartPolicy,
      scheduleTimeout,
      tags,
      ttyAllocation,
      waitForJobs,
      workingDirectory,
      mlflow,
      envs,
      volumes,
      passConfig,
    }) => {
      try {
        setLoading(true);

        const lifespanMinutes = Number(lifespan.replace('d', '')) * 24 * 60;
        const formattedScheduleTimeout =
          scheduleTimeout > 0 ? scheduleTimeout * 60 : undefined;
        const formattedName = name && formatModelName(name);
        const { secrets, vars } = envs.reduce(
          (envs, { name, value, type }) => {
            const { secrets, vars } = envs;
            const formattedOrganizationName = organizationName
              ? `/${organizationName}`
              : '';

            if (type === 'secret') {
              envs.secrets = {
                ...secrets,
                [name]: `secret://${clusterName}${formattedOrganizationName}/${projectName}/${value}`,
              };
            } else {
              envs.vars = { ...vars, [name]: value };
            }

            return envs;
          },
          {
            secrets: {},
            vars: {},
          } as EnvironmentAccumulator,
        );

        const { id } = await createJob({
          organizationName,
          clusterName: clusterName!,
          projectName: projectName!,
          presetName,
          restartPolicy,
          waitForJobs,
          privilegedMode,
          image: processImage(image),
          lifespanMinutes,
          priority,
          httpAuth,
          httpPort,
          name: formattedName,
          description,
          command,
          entrypoint,
          extendedSharedMemory,
          scheduleTimeout: formattedScheduleTimeout,
          tags,
          ttyAllocation,
          workingDirectory,
          env: { ...vars, mlflow },
          secretEnv: secrets,
          volumes,
          passConfig: passConfig === 'true',
        });
        navigate(path.job(id));
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <Layout title="Create New Job">
      <Helmet
        title={makeTitle('Create Job', 'Jobs', '%p', '%c')}
        description="Set up and customize jobs with specific parameters using our intuitive job constructor. Tailor each job to your needs and streamline your workflow with personalized configurations"
      />
      <Layout.Content className="flex gap-10">
        <JobConstructorNavigator navigator={jobNavigator} />
        <FormProvider {...methods}>
          <form
            className="flex flex-1 justify-center"
            onSubmit={handleJobCreate}
          >
            <Theme.Container className="relative flex w-full max-w-[720px] flex-col gap-20 pb-6">
              <JobConstructorSection {...jobNavigator.image} number={1}>
                <JobImageField error={errors.image} />
                <Field.Input
                  {...register('entrypoint')}
                  label="Entrypoint"
                  className="w-full"
                  note="Job entrypoint"
                  error={errors.entrypoint}
                />
                <Field.Input
                  {...register('command')}
                  label="Command"
                  className="w-full"
                  note="Job command"
                  error={errors.command}
                />
                <Field.Input
                  {...register('workingDirectory')}
                  label="Working Directory"
                  className="w-full"
                  note="Job working directory"
                  error={errors.workingDirectory}
                />
                <JobConstructorEnvironments />
                <Field.Note className="-mt-2">
                  Job environment variables
                </Field.Note>
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.resources} number={2}>
                <JobPresetField error={errors.presetName} />
                <JobConstructorResources />
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.integrations} number={3}>
                <JobMlflowField />
                <Controller
                  control={control}
                  name="passConfig"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    const displayValue = value === 'true' ? 'On' : 'Off';

                    return (
                      <Field.CustomSelect
                        value={value}
                        error={error?.message}
                        onChange={(value) => onChange({ target: { value } })}
                      >
                        <div className="flex items-center gap-2" slot="value">
                          <Logo.Icon className="h-auto w-[24px] text-white" />
                          <div className="text-left">
                            <p className="text-caption text-neural-03">
                              Apolo Engine
                            </p>
                            <p className="capitalize">{displayValue}</p>
                          </div>
                        </div>
                        <div
                          className="flex items-center gap-2"
                          slot="placeholder"
                        >
                          <Logo.Icon className="h-auto w-[24px] text-white" />
                          <p className="text-neural-04">Apolo Engine</p>
                        </div>
                        <Field.SelectItem
                          value="false"
                          className="min-w-[240px]"
                        >
                          Off
                        </Field.SelectItem>
                        <Field.SelectItem
                          value="true"
                          className="min-w-[240px]"
                        >
                          On
                        </Field.SelectItem>
                      </Field.CustomSelect>
                    );
                  }}
                />
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.networking} number={4}>
                <Field.Input
                  {...register('httpPort')}
                  type="number"
                  label="HTTP Port"
                  note="HTTP port to expose"
                  error={errors.httpPort}
                />
                <Field.Checkbox
                  {...register('httpAuth')}
                  disabled={!isNumber(httpPortWatcher)}
                  error={errors.httpAuth}
                >
                  HTTP authentication
                </Field.Checkbox>
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.metadata} number={5}>
                <Field.Input
                  {...register('name')}
                  label="Name"
                  className="w-full"
                  note="Job name"
                  error={errors.name}
                />
                <Field.Input
                  {...register('description')}
                  label="Description"
                  className="w-full"
                  note="Job description"
                  error={errors.description}
                />
                <JobConstructorMetadaTags error={errors.tags} />
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.scheduling} number={6}>
                <div className="flex flex-col gap-6">
                  <Field.Select
                    {...register('restartPolicy')}
                    label="Restart Policy"
                    defaultValue="never"
                    error={errors.restartPolicy}
                  >
                    <option value="always">Always</option>
                    <option value="never">Never</option>
                    <option value="on-failure">On failure</option>
                  </Field.Select>
                  <Field.Input
                    {...register('scheduleTimeout')}
                    type="number"
                    label="Schedule Timeout (minutes)"
                    note="Maximum wait time for job scheduling"
                    error={errors.scheduleTimeout}
                  />
                  <Field.Select
                    {...register('priority')}
                    label="Priority"
                    note="Job scheduling priority"
                    defaultValue="normal"
                    error={errors.priority}
                  >
                    <option value="low">Low</option>
                    <option value="normal">Normal</option>
                    <option value="high">High</option>
                  </Field.Select>
                  <Field.Checkbox
                    {...register('waitForJobs')}
                    error={errors.waitForJobs}
                  >
                    Wait for jobs quota
                  </Field.Checkbox>
                  <Field.Select
                    {...register('lifespan')}
                    required
                    label="Lifespan"
                    note="Max amount of time the job will be running"
                    error={errors.lifespan}
                  >
                    <option value="1d">1 Day</option>
                    <option value="10d">10 Days</option>
                    <option value="30d">30 Days</option>
                    <option value="90d">90 Days</option>
                    <option value="365d">365 Days</option>
                    <option value="3650d">3650 Days</option>
                  </Field.Select>
                </div>
              </JobConstructorSection>
              <JobConstructorSection {...jobNavigator.advanced} number={7}>
                <div className="flex flex-col gap-6">
                  <Field.Checkbox
                    {...register('extendedSharedMemory')}
                    error={errors.extendedSharedMemory}
                  >
                    Request extended &quot;/dev/shm&quot; space
                  </Field.Checkbox>
                  <Field.Checkbox
                    {...register('privilegedMode')}
                    containerClassName="-mt-4"
                    error={errors.privilegedMode}
                  >
                    Launch the job in a privileged mode
                  </Field.Checkbox>
                  <Field.Checkbox
                    {...register('ttyAllocation')}
                    containerClassName="-mt-4"
                    error={errors.ttyAllocation}
                  >
                    Allocate TTY
                  </Field.Checkbox>
                </div>
              </JobConstructorSection>
              <Modal.Footer sticky className="-mt-8 px-0">
                <Button
                  type="submit"
                  loading={loading}
                  className="px-10 capitalize"
                >
                  Submit new job
                </Button>
              </Modal.Footer>
            </Theme.Container>
          </form>
        </FormProvider>
      </Layout.Content>
    </Layout>
  );
};
