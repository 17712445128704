import { useLocation } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { SidebarItem } from '@typings';
import { PATH } from '@constants';
import { useContextSearchParams } from '@hooks';
import { ensureArray } from '@utils';
import { sidebarItems } from '@content';

import { Icon, Link, Render } from '@components';
import { SidebarSupportLink } from '@components/Ui';
import { UserPanelMenu } from '@components/UserPanel';

type Props = {
  showFeatures?: boolean;
};

export const LayoutSidebar = ({ showFeatures = true }: Props) => {
  const { pathname } = useLocation();
  const { contextSearchParams } = useContextSearchParams();

  const makeSidebarItem = ({
    name,
    path,
    regularIcon,
    solidIcon,
  }: SidebarItem) => {
    const paths = ensureArray(path);
    const [sidebarPath] = paths;
    const active = paths.some((path) => pathname.includes(path));
    const icon = active ? solidIcon : regularIcon;

    return (
      <Link
        theme
        key={name}
        to={`${sidebarPath}?${contextSearchParams}`}
        className={clsx(
          'flex items-center gap-3 rounded-lg px-4 py-3 text-neural-04 transition-colors hover:text-rebecca',
          { 'bg-neural-01 text-rebecca font-500': active },
        )}
      >
        <Icon icon={icon} className="text-[20px]" />
        {name}
      </Link>
    );
  };

  return (
    <div className="fixed bottom-0 left-0 top-20 z-20 flex w-[280px] flex-col bg-white px-4 pb-8 pt-10">
      <Render if={showFeatures}>
        <div className="flex flex-col gap-2 overflow-auto pb-4">
          {sidebarItems.map(makeSidebarItem)}
        </div>
      </Render>
      <div className="mt-auto">
        <SidebarSupportLink />
        <Link
          blank
          external
          variant="ghost"
          to={PATH.DOCS}
          className="group items-center justify-start gap-3 px-4 text-body text-neural-04 transition-colors"
        >
          <Icon icon={faQuestionCircle} className="text-[20px]" />
          Docs
        </Link>
        <UserPanelMenu />
      </div>
    </div>
  );
};
