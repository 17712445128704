import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  faCircleXmark,
  faFlag,
  faMicrochip,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons';

import { App, Job } from '@typings';
import { cn } from '@utils';

import { Badge, Icon, Render, Tooltip } from '@components';

dayjs.extend(relativeTime);

type Props = {
  className?: string;
  status: Job.Status | App.Status;
  createdAt?: string;
  description?: string | null;
};

export const JobStatus = ({
  className,
  status,
  createdAt,
  description,
}: Props) => {
  const title = description ?? '';

  const icon = (() => {
    switch (status) {
      case 'pending':
        return faFlag;
      case 'succeeded':
        return faTrophy;
      case 'failed':
      case 'cancelled':
        return faCircleXmark;
      default:
        return faMicrochip;
    }
  })();

  const badgeVariant = (() => {
    switch (status) {
      case 'pending':
      case 'cancelled':
        return 'yellow';
      case 'succeeded':
        return 'green';
      case 'failed':
        return 'red';
      default:
        return 'grey';
    }
  })();

  return (
    <Badge
      className={cn('flex w-max items-center gap-1.5', className)}
      variant={badgeVariant}
    >
      <Icon icon={icon} className="h-4 w-4 text-[14px]" />
      <Tooltip disabled={!description} className="w-60">
        <div slot="trigger" title={title}>
          <p className="capitalize">{status}</p>
          <Render if={createdAt}>
            <p className="text-caption-3">{dayjs(createdAt).fromNow()}</p>
          </Render>
        </div>
        {title}
      </Tooltip>
    </Badge>
  );
};
