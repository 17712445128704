import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { useTenantRole } from '@hooks';

import * as Page from '@pages';

export const OrganizationSettingsRoutes = () => {
  const { isOrganizationManager, isInitialized } = useTenantRole();

  if (isInitialized && !isOrganizationManager) {
    return <Navigate replace to={PATH.HOME} />;
  }

  return (
    <Routes>
      <Route path="users" element={<Page.OrganizationUsersSettings />} />
      <Route
        path="*"
        element={<Navigate replace to={PATH.ORGANIZATION_SETTINGS_USERS} />}
      />
    </Routes>
  );
};
