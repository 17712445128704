import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { useTenantRole } from '@hooks';
import { useCluster } from '@hooks/cluster';

import * as Page from '@pages';
import { Layout } from '@components/Layouts';
import { ChaseSpinner } from '@components/Shared';

export const ClusterSettingsRoutes = () => {
  const { loading, nodePools, resourcePresets, getCluster } = useCluster();
  const { isClusterManager, isTenantManager, isInitialized } = useTenantRole();

  const isManager = isClusterManager || isTenantManager;
  const defaultPath = isManager
    ? PATH.CLUSTER_SETTINGS
    : PATH.CLUSTER_SETTINGS_USERS;

  if (!isInitialized) {
    return (
      <Layout isProjectReffered={false} title="Cluster Settings">
        <Layout.Content>
          <div className="mt-24 flex justify-center">
            <ChaseSpinner color="black" className="h-14 w-14" />
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route
        element={
          <Page.ClusterSettings isManager={isManager} loading={loading} />
        }
      >
        {isManager && (
          <>
            <Route index element={<Page.ClusterGeneralSettings />} />
            <Route path="general" element={<Page.ClusterGeneralSettings />} />
            <Route
              path="nodes"
              element={
                <Page.ClusterNodesSettings
                  nodePools={nodePools}
                  getCluster={getCluster}
                />
              }
            />
          </>
        )}
        <Route
          path="users"
          element={<Page.ClusterUsersSettings isManager={isManager} />}
        />
        <Route
          path="reports"
          element={<Page.ClusterSettingsReports isManager={isManager} />}
        />
        <Route
          path="resources"
          element={
            <Page.ClusterResourcesSettings
              isManager={isManager}
              resourcePresets={resourcePresets}
              nodePools={nodePools}
              getCluster={getCluster}
            />
          }
        />
        <Route path="*" element={<Navigate replace to={defaultPath} />} />
      </Route>
    </Routes>
  );
};
