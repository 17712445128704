import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  faPenToSquare,
  faTrash,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings, Role } from '@typings';
import { getProjectUsers } from '@services';
import { configSelector, contextNamesSelector, userSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Modal,
  Table,
} from '@components';
import {
  ProjectEditUserModal,
  ProjectInviteMember,
  ProjectRemoveUserModal,
} from '@components/Project';

type Props = {
  isManager: boolean;
};

export const ProjectUsersSettingsOutlet = ({ isManager }: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { username } = useSelector(userSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const {
    list: users,
    isFetched,
    getList,
  } = useFetchList<ClusterSettings.User<Role>[]>({
    getList: getProjectUsers,
    fetchOnMount: false,
  });
  const { makeTitle } = useHelmetTitle();

  const [search, setSearch] = useState('');

  const getUsers = useCallback(async () => {
    if (clusterName) {
      getList({ adminUrl, clusterName, organizationName, projectName });
    }
  }, [adminUrl, clusterName, organizationName, projectName, getList]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const makeItem = ({
    role,
    userName,
    userInfo: { email, createdAt },
  }: ClusterSettings.User<Role>) => {
    const date = createdAt && dayjs(createdAt).format('DD.MM.YYYY');

    return (
      <Table.Row key={email}>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell className="flex-row">
          <div className="flex gap-4">
            <Modal
              disabled={!isManager}
              content={
                <ProjectEditUserModal
                  username={userName}
                  role={role}
                  getUsers={getUsers}
                />
              }
            >
              <Button
                disabled={!isManager}
                variant="ghost"
                className="h-6 w-6 p-0 text-[18px]"
              >
                <Icon icon={faPenToSquare} />
              </Button>
            </Modal>
            <Modal
              disabled={!isManager}
              content={
                <ProjectRemoveUserModal
                  username={userName}
                  getUsers={getUsers}
                />
              }
            >
              <Button
                variant="ghost-error"
                className="h-6 w-6 p-0 text-[18px]"
                disabled={userName === username || !isManager}
              >
                <Icon icon={faTrash} />
              </Button>
            </Modal>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? users.filter(
          ({ userName, userInfo: { email } }) =>
            userName.includes(search) || email.includes(search),
        )
      : users;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(4,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>Role</Table.Head>
            <Table.Head>Email</Table.Head>
            <Table.Head>Sign up Date</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{list.map(makeItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <Helmet title={makeTitle('Users', 'Project Settings', '%p', '%c')} />
      <div className="flex gap-10">
        <Modal
          disabled={!isManager}
          content={<ProjectInviteMember getUsers={getUsers} />}
        >
          <Button
            disabled={!isManager}
            variant="rebecca"
            className="gap-3 capitalize"
          >
            <Icon icon={faUserPlus} />
            Invite people
          </Button>
        </Modal>
        <Field.Input
          containerClassName="flex-1"
          className="border-neural-03"
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      {renderContent()}
    </div>
  );
};
