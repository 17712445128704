import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Job } from '@typings';
import { as, capitalize } from '@utils';

import { Render } from '@components';

import { JobDetailsItem } from './JobDetailsItem';

dayjs.extend(relativeTime);

type Props = {
  statuses: Job.TransitionStatus[];
};

export const JobDetailsStatuses = ({ statuses }: Props) => {
  const makeStatus = ({
    description,
    reason,
    status,
    transitionTime,
  }: Job.TransitionStatus) => {
    const timePassed = dayjs(transitionTime).fromNow();
    const formattedStatus = capitalize(status);
    const title = `${capitalize(status)} (${timePassed})`;

    return (
      <JobDetailsItem
        key={transitionTime}
        title={title}
        className="word-break-case gap-0"
      >
        <p>{as(reason, formattedStatus)}</p>
        <Render if={description}>
          <p className="mt-1 text-caption text-neural-03">{description}</p>
        </Render>
      </JobDetailsItem>
    );
  };

  return <>{statuses.slice().reverse().map(makeStatus)}</>;
};
