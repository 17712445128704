import { tv } from './tailwind';

export const buttonVariants = tv({
  base: 'relative inline-flex cursor-pointer items-center justify-center rounded-xl py-1 px-4 transition-colors text-button h-12',
  variants: {
    variant: {
      rebecca:
        'bg-rebecca text-white  hover:bg-black disabled:bg-neural-01 disabled:text-neural-03',
      secondary:
        'bg-neural-01 text-black hover:bg-neural-02 active:bg-neural-03 disabled:bg-neural-01 disabled:text-neural-03',
      ghost:
        'text-black hover:text-primary active:text-[#010A1B] disabled:text-neural-03',
      'ghost-primary':
        'text-primary hover:text-primary-medium active:text-primary-dark disabled:text-neural-03',
      'ghost-error':
        'text-error hover:text-error-dark active:text-error disabled:text-error-light',
      'error-outline':
        'text-error hover:text-error-dark border border-error hover:border-error-dark active:border-error active:text-error disabled:text-error-light disabled:border-error-light',
      'primary-outline':
        'text-primary hover:text-white active:text-white bg-transparent hover:bg-primary-medium active:bg-primary-dark border border-primary hover:border-primary-medium active:border-primary-dark disabled:text-neural-03 disabled:border-neural-01 disabled:bg-neural-01',
      primary:
        'bg-primary text-white hover:bg-primary-medium active:bg-primary-dark disabled:bg-neural-01 disabled:text-neural-03',
      error:
        'bg-error-subtle text-error hover:text-white active:text-white hover:bg-error active:bg-error-dark disabled:bg-neural-01 disabled:text-neural-02',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export const fieldVariants = tv({
  base: 'h-14 px-4 rounded-lg bg-neural-01 text-rebecca text-body placeholder:text-neural-04 border border-transparent hover:bg-primary-subtle focus:border-primary disabled:text-neural-03 disabled:pointer-events-none w-full peer appearance-none',
  variants: {
    variant: {
      static: 'transition-colors py-1',
      floating: 'block transition-all py-2',
    },
    label: {
      true: 'pt-4',
    },
    error: {
      true: '!border-error',
    },
  },
  defaultVariants: {
    variant: 'floating',
  },
});
