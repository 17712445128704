import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { faEnvelope } from '@fortawesome/pro-thin-svg-icons';

import { JWT_TOKEN } from '@constants';
import { getConfig, getUserClusters } from '@thunks';
import { useDispatch } from '@hooks';
import { getUsernameFromToken, localStorage } from '@utils';

import { ChaseSpinner, Render } from '@components';
import { Layout } from '@components/Layouts';

export const EmailVerificationPage = () => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const run = async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'off' });

        localStorage.set<string>(JWT_TOKEN, token);

        const username = getUsernameFromToken(token);

        const { adminUrl } = await dispatch(getConfig());

        if (username) {
          await dispatch(getUserClusters(adminUrl, username));
        }
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setLoading(false);
      }
    };

    run();
  }, [dispatch, getAccessTokenSilently]);

  return (
    <Layout
      showFeatures={false}
      clusterContextTracker={false}
      projectContextTracker={false}
      className="flex justify-center"
    >
      <Layout.EmptyContent
        icon={faEnvelope}
        title="Email Verification"
        text="Please verify your email before logging in"
      >
        <Render if={loading}>
          <ChaseSpinner color="primary" className="h-10 w-10" />
        </Render>
      </Layout.EmptyContent>
    </Layout>
  );
};
