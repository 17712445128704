import { useCallback } from 'react';
import { useImmer } from 'use-immer';
import { faDownload, faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import { AsyncFunction, Job as JobType } from '@typings';
import { killRunningJob } from '@services';
import { userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, toastifyResponseError } from '@utils';

import { Button, Icon, Link, Modal, Render } from '@components';
import { SaveJobImageModal } from '@components/Modals';
import { Job } from '@components/Ui';

type Loading = {
  isKilling: boolean;
  isRefreshing: boolean;
  isRerunning: boolean;
};

type Props = {
  isApp?: boolean;
  job: JobType.Model | null;
  getJob: AsyncFunction;
};

export const JobDetailsHandleCenter = ({
  isApp = false,
  job,
  getJob,
}: Props) => {
  const { username } = useSelector(userSelector);

  const [loading, setLoading] = useImmer<Loading>({
    isKilling: false,
    isRefreshing: false,
    isRerunning: false,
  });

  const {
    id: jobId,
    owner,
    status,
    httpUrl,
    httpUrlNamed,
    history,
  } = as.o<JobType.Model>(job);
  const isRunningJob = ['pending', 'running'].includes(status);
  const isJobRerunDisabled = owner !== username || !username;
  const isOpenLinkDisabled = !httpUrlNamed && !httpUrl;

  const handleLoading = useCallback(
    (name: keyof Loading, value: boolean) => {
      setLoading((state) => {
        state[name] = value;
      });
    },
    [setLoading],
  );

  const handleJobRefresh = useCallback(async () => {
    try {
      handleLoading('isRefreshing', true);

      await getJob();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      handleLoading('isRefreshing', false);
    }
  }, [handleLoading, getJob]);

  const handleJobKill = async () => {
    try {
      handleLoading('isKilling', true);

      await killRunningJob(jobId);
      await getJob();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      handleLoading('isKilling', false);
    }
  };

  return (
    <div className="mb-8 flex justify-end gap-2">
      <Job.Status {...history} className="mr-auto" />
      <Render if={!isApp}>
        <Link
          blank
          external
          theme
          to={httpUrlNamed ?? httpUrl}
          disabled={isOpenLinkDisabled}
        >
          <Button
            disabled={isOpenLinkDisabled}
            className="flex items-center gap-1.5 px-6"
          >
            Open
            <Icon icon={faExternalLink} className="text-[20px]" />
          </Button>
        </Link>
        <Modal
          disabled={status !== 'running'}
          content={<SaveJobImageModal jobId={jobId} />}
        >
          <Button
            disabled={status !== 'running'}
            className="flex items-center gap-1.5 capitalize"
          >
            <Icon icon={faDownload} className="text-[20px]" />
            Save image
          </Button>
        </Modal>
      </Render>
      <Button
        variant="primary-outline"
        loading={loading.isRefreshing}
        disabled={!job}
        onClick={handleJobRefresh}
      >
        Refresh
      </Button>
      <Button
        variant="primary-outline"
        loading={loading.isRerunning}
        disabled={isJobRerunDisabled}
      >
        {isApp ? 'Reinstall' : 'Rerun'}
      </Button>
      <Button
        variant="error-outline"
        loading={loading.isKilling}
        disabled={!isRunningJob}
        onClick={handleJobKill}
      >
        {isApp ? 'Uninstall' : 'Kill'}
      </Button>
    </div>
  );
};
