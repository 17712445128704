import { isNumber, isString } from '@utils';

type Props = {
  title: string;
  value: number | string;
};

export const JobResource = ({ title, value }: Props) => {
  const height = isNumber(value) ? ((value as number) * 100) / 60 : 30;
  const text = isString(value) ? value : `${(value as number) * 100}%`;

  return (
    <div className="flex items-end gap-3">
      <p className="text-right text-footnote-large text-neural-04">
        <span className="font-600">{text}</span>
        <br />
        <span>{title}</span>
      </p>
      <div className="relative h-[60px] w-6 shrink-0 overflow-hidden rounded-[4px] bg-stroke">
        <div
          className="absolute inset-x-0 bottom-0 bg-primary"
          style={{ height: `${height}px` }}
        />
      </div>
    </div>
  );
};
