import dayjs from 'dayjs';

import { Bucket } from '@typings';
import { noop } from '@utils';
import { bucketProviderFormattedName } from '@content';

import { Layout } from '@components/Layouts';
import { DrawerDetailsItem } from '@components/Ui';

import { BucketCredentials } from './BucketCredentials';

type Props = {
  bucket?: Bucket.Model;
  credentials?: Bucket.PersistentCredentials[];
  getCredentials: () => Promise<void>;
  resetBucketId?: () => void;
};

export const BucketDetails = ({
  bucket,
  credentials = [],
  getCredentials,
  resetBucketId = noop,
}: Props) => {
  const { id, name, createdAt, owner, provider, imported } =
    bucket ?? ({} as Bucket.Model);
  const formattedDate = dayjs(createdAt).format('MM/DD/YYYY');

  return (
    <Layout.ContentDrawer
      className="flex flex-col gap-6"
      title={name}
      onClose={resetBucketId}
      show={!!id}
    >
      <BucketCredentials
        bucketId={id}
        bucketName={name}
        credentials={credentials}
        getCredentials={getCredentials}
      />
      <DrawerDetailsItem title="ID" text={id} />
      <DrawerDetailsItem title="Owner" text={owner} />
      <DrawerDetailsItem title="Details" />
      <p className="-mt-3 flex items-start text-neural-04">
        <span className="shrink-0 basis-[96px]">Provider</span>
        {bucketProviderFormattedName[provider] ?? provider}
      </p>
      <p className="-mt-3 flex items-start text-neural-04">
        <span className="shrink-0 basis-[96px]">Created</span>
        {formattedDate}
      </p>
      <p className="-mt-3 flex items-start text-neural-04">
        <span className="shrink-0 basis-[96px]">Imported</span>
        {imported ? 'Yes' : 'No'}
      </p>
    </Layout.ContentDrawer>
  );
};
