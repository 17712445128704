import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';

/**
 * Context tracker
 */
type Options = {
  organization?: boolean;
  cluster?: boolean;
  project?: boolean;
};

export const useContextParamsTracker = ({
  project: projectTracker,
  cluster: clusterTracker,
}: Options = {}) => {
  const { clusterName, projectName } = useSelector(contextNamesSelector);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      (searchParams) => {
        /**
         * Hook conflicts with `UserPanelCredits` search params,
         * that manages credits purchase and its params management
         *
         * Source https://linear.app/apolo/issue/ENG-253/fix-notifications-after-credits-purchase
         */
        searchParams.delete('buy-credits-status');
        searchParams.delete('credits');

        if (projectName) {
          searchParams.set('project', projectName);
        }

        if (!projectTracker) {
          searchParams.delete('project');
        }

        return searchParams;
      },
      { replace: true },
    );
  }, [projectTracker, projectName, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (searchParams) => {
        if (clusterName) {
          searchParams.set('cluster', clusterName);
        }

        if (!clusterTracker) {
          searchParams.delete('cluster');
        }

        return searchParams;
      },
      { replace: true },
    );
  }, [clusterTracker, clusterName, setSearchParams]);
};

export const useContextSearchParams = ({
  project: projectTracker = true,
  cluster: clusterTracker = true,
}: Options = {}) => {
  const { clusterName, projectName } = useSelector(contextNamesSelector);

  const contextSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams();

    if (clusterTracker && clusterName) {
      searchParams.set('cluster', clusterName);
    }

    if (projectTracker && projectName) {
      searchParams.set('project', projectName);
    }

    return searchParams.toString();
  }, [projectName, clusterName, projectTracker, clusterTracker]);

  return { contextSearchParams };
};
