import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

import { SUPPORT_EMAIL } from '@constants';
import { sentry } from '@features';
import { useInitialization } from '@hooks/useInitialization';

import { Link, Theme } from '@components';
import { ApplicationRoutes } from '@components/Routes';
import { ToastContainer } from '@components/Toast';

import { Layout } from './Layouts';

sentry.init();

export const Application = () => {
  const { isInitialized, isError } = useInitialization();

  if (!isInitialized) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="duration-1000 animate-in fade-in">
          <Theme.Logo className="h-20 w-auto text-black" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <Layout.EmptyContent
        icon={faCircleXmark}
        title="Cannot fetch configuration"
      >
        <p slot="text">
          For further assistance, please contact support at{' '}
          <Link
            external
            theme
            to={`mailto:${SUPPORT_EMAIL}`}
            className="text-primary underline hover:no-underline"
          >
            {SUPPORT_EMAIL}
          </Link>
          .
        </p>
      </Layout.EmptyContent>
    );
  }

  return (
    <>
      <ToastContainer />
      <ApplicationRoutes />
    </>
  );
};
