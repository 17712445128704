import { useCallback, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { Job } from '@typings';
import { PATH } from '@constants';
import { getJob } from '@services';
import { as, path } from '@utils';

import * as Page from '@pages';
import { ChaseSpinner, Icon, Link } from '@components';
import { JobLayout, Layout } from '@components/Layouts';

type Props = {
  isJobPage: boolean;
};

export const JobRoutes = ({ isJobPage }: Props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const jobId = params.jobId!;

  const [job, setJob] = useState<Job.Model | null>(null);

  const { id, name } = as.o<Job.Model>(job);
  const title = name ?? id ?? jobId;

  const fetchJob = useCallback(async () => {
    try {
      const job = await getJob({ jobId: jobId! });

      setJob(job);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [jobId]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 15_000;

    fetchJob();

    const intervalId = setInterval(fetchJob, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchJob]);

  const getBackUrl = (): string => {
    const projectId = searchParams.get('projectId');
    const flowName = searchParams.get('flowName');
    const isBakeExecutorPage = projectId && flowName;

    if (isBakeExecutorPage) {
      return path.flowBakes(projectId, flowName);
    }

    if (isJobPage) {
      return PATH.JOBS;
    }

    return PATH.APPS_INSTALLED;
  };

  const getdefaultPath = () => {
    const url = isJobPage ? path.job : path.app;

    return url(id, Job.Tab.Overview);
  };

  if (!job) {
    return (
      <Layout>
        <div slot="header" className="flex min-w-0 items-center gap-4">
          <Link
            variant="ghost"
            to={getBackUrl()}
            className="h-auto p-0 text-[24px] text-neural-03"
          >
            <Icon icon={faChevronLeft} className="h-10 w-10" />
          </Link>
          <h3 className="truncate text-h4 text-white">{title}</h3>
        </div>
        <Layout.Content className="relative flex justify-center">
          <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route
        element={
          <JobLayout isJobPage={isJobPage} job={job} getJob={fetchJob} />
        }
      >
        <Route index element={<Page.JobOverview job={job} />} />
        <Route path="details" element={<Page.JobOverview job={job} />} />
        <Route path="logs" element={<Page.JobLog />} />
        <Route
          path="observability"
          element={<Page.JobObservability job={job} />}
        />
        <Route path="*" element={<Navigate replace to={getdefaultPath()} />} />
      </Route>
    </Routes>
  );
};
