import { PREFIX_TO_FACTOR } from '@constants';

type Options = Partial<{
  separator: string;
  fix: number;
}>;

export const parsableMemoryPattern = `^(\\d+\\.?\\d*)(${Object.keys(
  PREFIX_TO_FACTOR,
).join('|')})?[bB]?$`;

export const getFormattedMemory = (
  value: number,
  options: Options = {},
): string => {
  const { separator = '', fix = 1 } = options;

  let size = value;
  const step = 10 ** 3;
  const suffixes = ['', 'K', 'M', 'G', 'T'];

  // eslint-disable-next-line no-restricted-syntax
  for (const suffix of suffixes) {
    if (size < step) {
      const formattedMemory =
        size % 1 === 0 ? size.toFixed(0) : size.toFixed(fix);
      return `${formattedMemory}${separator}${suffix}B`;
    }
    size /= step;
  }

  const lastSuffix = suffixes[suffixes.length - 1];

  return `${size.toFixed(fix)}${separator}${lastSuffix}B`;
};

export function getParsedMemory(value: string): number | null {
  const match = value.match(parsableMemoryPattern);

  if (match === null) {
    return null;
  }

  const quantity = Number(match[1]);
  const suffix = match[2] ?? '';
  return quantity * PREFIX_TO_FACTOR[suffix as keyof typeof PREFIX_TO_FACTOR];
}
