import dayjs from 'dayjs';

import { Disk, Payload } from '@typings';
import { shareAccess } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, getFormattedMemory, noop, path } from '@utils';

import { Button, Modal, Render } from '@components';
import { Layout } from '@components/Layouts';
import { ShareAccessModal } from '@components/Modals';
import { DrawerDetailsItem } from '@components/Ui';

import { DeleteDiskModal } from './Modals';

type Props = {
  disk?: Disk;
  resetDiskId?: () => void;
  getDisks: () => Promise<void>;
};

export const DiskDetails = ({ disk, getDisks, resetDiskId = noop }: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { usersUrl } = as.c(cluster);
  const { id, name, uri, createdAt, status, owner, storage, lifeSpan } =
    disk ?? ({} as Disk);
  const formattedDate = dayjs(createdAt).format('MM/DD/YYYY');

  const getFormattedLifespan = () => {
    const minutes = Math.ceil((lifeSpan ?? 0) / 60);
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = minutes % 60;

    const timeParts = [];

    if (days > 0) {
      timeParts.push(`${days}d`);
    }

    if (hours > 0) {
      timeParts.push(`${hours}h`);
    }

    if (remainingMinutes > 0) {
      timeParts.push(`${remainingMinutes}m`);
    }

    return timeParts.join(' ');
  };

  const handleShareAccess = async ({
    permission,
    username,
  }: Payload.ShareAccess) => {
    const uri = path.create(
      'disk:/',
      clusterName,
      organizationName,
      projectName,
      id,
    );

    await shareAccess({ action: permission, uri, username, usersUrl });
  };

  return (
    <Layout.ContentDrawer
      className="flex flex-col gap-6"
      containerClassName="w-[360px]"
      title={name}
      onClose={resetDiskId}
      show={!!id}
    >
      <DrawerDetailsItem title="ID" text={id} />
      <Render if={uri}>
        <DrawerDetailsItem title="URI" text={uri} />
      </Render>
      <DrawerDetailsItem title="Status" text={status} />
      <DrawerDetailsItem title="Owner" text={owner} />
      <DrawerDetailsItem title="Storage" text={getFormattedMemory(storage)} />
      <Render if={lifeSpan}>
        <DrawerDetailsItem title="Lifespan" text={getFormattedLifespan()} />
      </Render>
      <DrawerDetailsItem title="Created" text={formattedDate} />
      <div className="flex gap-4">
        <Modal
          content={
            <ShareAccessModal
              title={`Sharing Disk "${name}"`}
              onShare={handleShareAccess}
            />
          }
        >
          <Button className="capitalize">Share access</Button>
        </Modal>
        <Modal
          content={<DeleteDiskModal id={id} name={name} getDisks={getDisks} />}
        >
          <Button variant="error" className="capitalize">
            Delete disk
          </Button>
        </Modal>
      </div>
    </Layout.ContentDrawer>
  );
};
