import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { AnyFunction } from '@typings';
import { cn } from '@utils';

import { Button, Icon } from '@components';

type Props = {
  disabled?: boolean;
  className?: string;
  icon?: IconProp | null;
  slot?: string;
  children?: React.ReactNode;
  onClick?: AnyFunction;
};

export const UserPanelButton = ({
  disabled,
  className,
  slot,
  icon = faCaretDown,
  children,
  onClick,
}: Props) => {
  return (
    <Button
      theme
      slot={slot}
      disabled={disabled}
      stopPropagation={false}
      className={cn(
        'flex h-14 shrink-0 items-center gap-2 rounded-lg bg-rebecca px-3 py-2 text-white transition-colors hover:bg-white/10 disabled:bg-rebecca',
        className,
      )}
      onClick={onClick}
    >
      {children}
      <Icon icon={icon} className="text-caption text-neural-03" />
    </Button>
  );
};
