/**
 * Shared formatters
 */

import { Any } from '@typings';

import { isArray } from '../typeof';

export const formatToCamelCase = (item: Any): unknown => {
  if (isArray(item)) {
    return item.map(formatToCamelCase);
  }

  if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      formatToCamelCase(value),
    ]),
  );
};

export const formatToSnakeCase = (input: unknown): unknown => {
  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      return input.map(formatToSnakeCase);
    }
    const formattedObject: unknown = {};

    Object.keys(input).forEach((key) => {
      const snakeCaseKey = key
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
        .toLowerCase();
      // @ts-expect-error todo: resolve error
      formattedObject[snakeCaseKey] = formatToSnakeCase(input[key]);
    });

    return formattedObject;
  }
  return input;
};

export const formatModelName = (name: string = '') =>
  name
    /**
     * Drops spaces at beginning and end
     */
    .trim()
    /**
     * Drops multiple spaces in the middle of a string
     */
    .replaceAll(/\s\s+/g, ' ')
    .replaceAll(/\//g, '')
    /**
     * Replaces spaces with `-`
     */
    .replaceAll(/\s/g, '-')
    .toLowerCase();

export const capitalize = (string: string = ''): string =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
