import { useState } from 'react';

import { Cluster, ModalProps } from '@typings';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, cn, getFormattedMemory, getGpuFromNodePool, noop } from '@utils';

import { Button, Modal, Render, Table } from '@components';

type Props = ModalProps & {
  onApply?: (nodePoolName: string) => void;
};

export const AppNodePoolsModal = ({
  onApply = noop,
  closeModal = noop,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);

  const [activeName, setActiveName] = useState('');

  const { resourcePoolTypes } = as.c(cluster);

  const nodePools = resourcePoolTypes.filter((nodePool) => {
    const { gpu } = getGpuFromNodePool(nodePool);

    return !!gpu;
  });

  const handleApply = () => {
    onApply(activeName);
    closeModal();
  };

  const makeItem = (nodePool: Cluster.ResourcePoolType) => {
    const { name, diskSize, memory, cpu } = nodePool;
    const { gpu, gpuModel } = getGpuFromNodePool(nodePool);
    const isActive = name === activeName;
    const className = cn(
      'cursor-pointer border-neural-01 py-2 transition-colors group-hover:bg-neural-01',
      { '!bg-neural-02': isActive },
    );

    return (
      <Table.Row key={name} onClick={() => setActiveName(name)}>
        <Table.Cell className={className}>{name}</Table.Cell>
        <Table.Cell className={className}>{cpu}</Table.Cell>
        <Table.Cell className={className}>
          {getFormattedMemory(memory)}
        </Table.Cell>
        <Table.Cell className={className}>
          {getFormattedMemory(diskSize)}
        </Table.Cell>
        <Table.Cell className={className}>
          <Render if={gpu}>
            <p>
              {gpu as number} x{' '}
              <span className="uppercase">{gpuModel as string}</span>
            </p>
          </Render>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Modal.Content
      title="Choose Node Pool"
      className="w-[1024px] overflow-auto"
    >
      <Table className="w-full auto-rows-min grid-cols-[repeat(5,minmax(max-content,auto))] overflow-auto bg-background">
        <Table.Header>
          <Table.Row>
            <Table.Head className="bg-background">Name</Table.Head>
            <Table.Head className="bg-background">CPU cores</Table.Head>
            <Table.Head className="bg-background">Memory</Table.Head>
            <Table.Head className="bg-background">Disk size</Table.Head>
            <Table.Head className="bg-background">GPU devices</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{nodePools.map(makeItem)}</Table.Body>
      </Table>
      <Modal.Footer>
        <Button className="px-10" disabled={!activeName} onClick={handleApply}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
