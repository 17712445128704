import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { cn, getSlots } from '@utils';

import { Icon, Slot } from '@components';

type Props = {
  className?: string;
  icon?: IconProp;
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

export const LayoutEmptyContent = ({
  className,
  icon,
  title,
  text,
  children,
}: Props) => {
  const { content, ...slots } = getSlots<'icon' | 'title' | 'text'>(children);

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center gap-10 px-10 pb-12 pt-40',
        className,
      )}
    >
      <Slot slot={slots.icon}>
        <Icon icon={icon} className="h-24 w-24 text-[88px] text-neural-03" />
      </Slot>
      <div className="flex max-w-[440px] flex-col gap-2 text-center">
        <Slot slot={slots.title}>
          <p className="text-h5">{title}</p>
        </Slot>
        <Slot slot={slots.text}>
          <p>{text}</p>
        </Slot>
      </div>
      {content}
    </div>
  );
};
