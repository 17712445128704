import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from '@typings';
import { PATH } from '@constants';
import { useImage } from '@hooks/image';
import { useImageName } from '@hooks/useImageName';
import { path } from '@utils';

import { ChaseSpinner, Field, Helmet, Table } from '@components';
import { ImageDetails } from '@components/Images';
import { Layout } from '@components/Layouts';
import { EmptyList } from '@components/Ui';

export const ImagesPage = () => {
  const navigate = useNavigate();
  const { loading, list, isEmpty, image, imageLoading, resetImage } =
    useImage();
  const { getImageName } = useImageName();

  const [search, setSearch] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const makeListItem = (name: Image.Name) => {
    return (
      <Table.Row
        key={name}
        className="cursor-pointer"
        onClick={() =>
          navigate(path.create(PATH.IMAGES, name), { replace: true })
        }
      >
        <Table.Cell>{getImageName(name)}</Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const filteredList = search
      ? list.filter((repository) => repository.includes(search))
      : list;

    if (loading) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return <EmptyList className="mt-20" title="No images found" />;
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-1 overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{filteredList.map(makeListItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout title="Images">
      <Helmet title="Images" />
      <Layout.Content className="flex items-start gap-10">
        <div className="flex flex-1 flex-col">
          <div className="mb-14 flex items-center gap-10">
            <Field.Input
              containerClassName="flex-1"
              className="border-neural-03"
              label="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {renderContent()}
        </div>
        <ImageDetails
          loading={imageLoading}
          image={image}
          resetImage={resetImage}
        />
      </Layout.Content>
    </Layout>
  );
};
