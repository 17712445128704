import { cn, getSlots } from '@utils';

import { Slot } from '@components';

type Props = {
  className?: string;
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

export const EmptyList = ({ className, title, text, children }: Props) => {
  const { content, ...slots } = getSlots<'title' | 'text'>(children);

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center gap-4',
        className,
      )}
    >
      <Slot slot={slots.title}>
        <p className="text-h5">{title}</p>
      </Slot>
      <Slot slot={slots.text}>
        <p className="text-h6 text-stroke">{text}</p>
      </Slot>
      {content}
    </div>
  );
};
