import { Payload, Project } from '@typings';
import { API_ADMIN } from '@constants';
import { axios } from '@features';

export const checkOrganizationExists = async (name: string) =>
  axios.get(`${API_ADMIN}/orgs/${name}`);

export const checkProjectExists = async ({
  organization,
  cluster,
  project,
}: Payload.CheckProjectExists) => {
  const url = organization
    ? `${API_ADMIN}/clusters/${cluster}/orgs/${organization}/projects/${project}`
    : `${API_ADMIN}/clusters/${cluster}/projects/${project}`;

  return axios.get(url);
};

export const createOrganization = async (name: string) =>
  axios.post<never, never, { name: string }>(`${API_ADMIN}/orgs`, { name });

export const createProject = async ({
  organization,
  cluster,
  payload,
}: Omit<Payload.CheckProjectExists, 'project'> & {
  payload: Payload.CreateProject;
}) => {
  const url = organization
    ? `${API_ADMIN}/clusters/${cluster}/orgs/${organization}/projects`
    : `${API_ADMIN}/clusters/${cluster}/projects`;

  return axios.post<never, Project, { name: string }>(url, payload);
};
