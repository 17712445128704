import { useCallback, useEffect, useState } from 'react';
import * as sentry from '@sentry/react';

import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, getParsedMemory } from '@utils';

const MIN_CPU = 0.5;
const MIN_MEMORY = getParsedMemory('1GB') as number;

type Options = {
  resourcePreset?: string;
  appName: string;
};

export const useResourcePresetName = ({ resourcePreset, appName }: Options) => {
  const cluster = useSelector(clusterContextSelector);

  const { resourcePresets } = as.c(cluster);

  const [presetName, setPresetName] = useState('');

  const report = useCallback(() => {
    sentry.captureMessage(
      `No resource preset name found for ${resourcePreset} for ${appName} app`,
    );
  }, [appName, resourcePreset]);

  const calculateResourcePresetName = useCallback(
    () =>
      resourcePresets
        .filter(({ cpu, memory }) => cpu >= MIN_CPU && memory >= MIN_MEMORY)
        .sort((a, b) =>
          a.cpu === b.cpu ? a.memory - b.memory : a.cpu - b.cpu,
        ),
    [resourcePresets],
  );

  useEffect(() => {
    const isResourcePresetFound = resourcePresets?.some(
      ({ name }) => name === resourcePreset,
    );

    if (resourcePreset && isResourcePresetFound) {
      setPresetName(resourcePreset);

      return;
    }

    const [preset] = calculateResourcePresetName();

    if (preset) {
      setPresetName(preset.name);
    } else {
      report();
    }
  }, [resourcePreset, resourcePresets, report, calculateResourcePresetName]);

  return { presetName };
};
