import { useCallback, useMemo } from 'react';

import { Hostname } from '@typings';
import { JWT_TOKEN } from '@constants';
import { getOriginFromToken, localStorage } from '@utils';

export const useHostname = () => {
  const origin = useMemo(() => {
    const token = localStorage.get<string | null>(JWT_TOKEN);
    const origin = getOriginFromToken(token);

    return origin;
  }, []);

  const checkHostname = useCallback(
    (host: string) =>
      window.location.origin.includes(host) || origin?.includes(host),
    [origin],
  );

  const title = useMemo(() => {
    if (checkHostname(Hostname.Scottdc)) {
      return 'Scott Data';
    }

    if (checkHostname(Hostname.Novoserve)) {
      return 'NovoServe';
    }

    if (checkHostname(Hostname.Imhpc)) {
      return 'iMHPC';
    }

    if (checkHostname(Hostname.Megaport)) {
      return 'Megaport';
    }

    if (checkHostname(Hostname.Wwt)) {
      return 'World Wide Technology';
    }

    return 'Apolo';
  }, [checkHostname]);

  return { title, checkHostname };
};
