import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faBoxesStacked } from '@fortawesome/pro-thin-svg-icons';

import { Bucket } from '@typings';
import { toast } from '@features';
import { getBuckets, getBucketsCredentials } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';
import { path, toastifyResponseError } from '@utils';
import { bucketProviderIcon } from '@content';

import { ChaseSpinner, Field, Helmet } from '@components';
import { BucketDetails } from '@components/Buckets';
import { Layout } from '@components/Layouts';
import { EmptyList, Icons, ListItem } from '@components/Ui';

export const BucketsPage = () => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const clickRef = useRef(0);
  const [search, setSearch] = useState('');
  const [bucketId, setBucketId] = useState<string | null>(null);
  const [credentials, setCredentials] = useState<
    Bucket.PersistentCredentials[]
  >([]);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const {
    list: buckets,
    isFetched,
    isEmpty,
    getList,
  } = useFetchList<Bucket.Model[]>({
    getList: getBuckets,
    fetchOnMount: false,
  });

  const { bucketsUrl } = cluster! ?? {};

  /**
   * Used for bucket details drawer
   */
  const bucket = buckets.find(({ id }) => id === bucketId);
  const bucketCredentials = credentials.filter(({ credentials }) =>
    credentials.some(({ bucketId: id }) => id === bucketId),
  );

  const getCredentials = useCallback(async () => {
    try {
      const credentials = await getBucketsCredentials({
        bucketsUrl,
        organizationName,
        projectName: projectName!,
      });

      setCredentials(credentials);
    } catch (error) {
      toastifyResponseError(error);
    }
  }, [organizationName, projectName, bucketsUrl]);

  const fetchBuckets = useCallback(async () => {
    try {
      if (projectName) {
        await getList({ bucketsUrl, organizationName, projectName });
        await getCredentials();
      }
    } catch (error) {
      toastifyResponseError(error);
    }
  }, [organizationName, projectName, bucketsUrl, getList, getCredentials]);

  useEffect(() => {
    fetchBuckets();
  }, [fetchBuckets]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const handleBucketIdReset = () => {
    setBucketId(null);
  };

  const resetClickCount = () => {
    clickRef.current = 0;
  };

  const handleBucketNavigate = (bucketId: string, bucketName: string) => {
    navigate(path.bucket(bucketId, bucketName));
  };

  const handleBucketClick = (bucket: Bucket.Model) => {
    /**
     * todo: set `gcp` as enum provider
     */
    const isGcpProvider = bucket.provider === 'gcp';
    const { id, name } = bucket;

    clickRef.current += 1;

    if (isGcpProvider && clickRef.current === 2) {
      toast.error('Buckets created with "gcp" cannot be viewed in the web UI');
      resetClickCount();
      return;
    }

    setTimeout(() => {
      if (clickRef.current === 1) {
        setBucketId(id);
      } else if (clickRef.current === 2) {
        handleBucketNavigate(id, name);
      }

      resetClickCount();
    }, 200);
  };

  const makeListItem = (bucket: Bucket.Model) => {
    const { id, name, provider } = bucket;
    /**
     * todo: fix typescript
     */
    // @ts-ignore
    const icon = bucketProviderIcon[provider];
    // @ts-ignore
    const Icon = Icons[icon];

    return (
      <ListItem.Grid
        key={id}
        isActive={id === bucketId}
        title={name ?? id}
        icon={faBoxesStacked}
        onClick={() => handleBucketClick(bucket)}
      >
        {icon && (
          <div
            slot="icon"
            className="flex h-14 w-20 items-center justify-center"
          >
            <Icon />
          </div>
        )}
      </ListItem.Grid>
    );
  };

  const renderContent = () => {
    const list = search
      ? buckets.filter(({ name }) => name.includes(search))
      : buckets;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return <EmptyList className="mt-20" title="No buckets found" />;
    }

    return (
      <div className="flex flex-wrap gap-10">{list.map(makeListItem)}</div>
    );
  };

  return (
    <Layout title="Buckets">
      <Helmet
        title={makeTitle('Buckets', '%p', '%c')}
        description="Efficiently manage your storage buckets. Utilize AWS, Google, Azure and more buckets to store, organize, and access your data seamlessly, ensuring optimal control and accessibility"
      />
      <Layout.Content className="flex items-start gap-10">
        <div className="flex flex-1 flex-col">
          <Field.Input
            containerClassName="mb-14 flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={handleSearchChange}
          />
          {renderContent()}
        </div>
        <BucketDetails
          bucket={bucket}
          credentials={bucketCredentials}
          getCredentials={getCredentials}
          resetBucketId={handleBucketIdReset}
        />
      </Layout.Content>
    </Layout>
  );
};
