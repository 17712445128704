import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { Cluster } from '@typings';
import { DEDICATED_APP_NAME, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { useResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  getFormattedMemory,
  getGpuFromNodePool,
  getParsedMemory,
  isString,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { appNavigator, dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  AppConstructorArguments,
  AppNodePoolField,
  JobConstructorNavigator,
  JobConstructorSection,
} from '@components/Job';
import { Layout } from '@components/Layouts';

type Schema = z.infer<ReturnType<typeof makeSchema>>;

type SchemaParams = {
  nodePools: Cluster.ResourcePoolType[];
};

const transformNumberFormat = (value: unknown) =>
  isString(value) ? value.replace(',', '.') : value;

const getNodePool = (
  nodePools: Cluster.ResourcePoolType[],
  nodePoolName?: string,
): null | Cluster.ResourcePoolType => {
  if (!nodePoolName) {
    return null;
  }

  return nodePools.find(({ name }) => name === nodePoolName)!;
};

const makeSchema = ({ nodePools }: SchemaParams) => {
  return (
    z
      .object({
        modelName: z.string().min(1),
        imageTag: z.string().min(1),
        nodePoolName: z.string().min(1),
        gpu: z.coerce.number().positive(),
        memory: z.coerce.number().positive(),
        memoryPrefix: z.string(),
        name: z.string(),
        cpu: z.coerce.number().positive(),
        ingressEnabled: z.boolean().optional(),
        args: z
          .object({
            value: z.string(),
          })
          .array(),
      })
      /**
       * `nodePoolName` must exist on this stage
       */
      .superRefine(({ memory, memoryPrefix, nodePoolName }, context) => {
        const MIN_MEMORY = 64 * 10 ** 6;
        const { memory: maxMemory } = getNodePool(nodePools, nodePoolName)!;
        const parsedMemory = getParsedMemory(`${memory}${memoryPrefix}`) ?? 0;
        const isMemoryNaN = Number.isNaN(memory);

        if (isMemoryNaN) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['memory'],
            message: 'Memory is not a number',
          });

          return z.NEVER;
        }

        if (!parsedMemory) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['memory'],
            message: 'Memory cannot be blank',
          });

          return z.NEVER;
        }

        if (parsedMemory < MIN_MEMORY) {
          context.addIssue({
            code: z.ZodIssueCode.too_small,
            type: 'number',
            inclusive: false,
            minimum: MIN_MEMORY,
            path: ['memory'],
            message: `Memory cannot be less than ${getFormattedMemory(
              MIN_MEMORY,
            )}`,
          });
        }

        if (parsedMemory > maxMemory) {
          context.addIssue({
            code: z.ZodIssueCode.too_big,
            type: 'number',
            inclusive: false,
            maximum: maxMemory,
            path: ['memory'],
            message: `Memory cannot be less than ${getFormattedMemory(
              MIN_MEMORY,
            )}`,
          });
        }

        if (parsedMemory > maxMemory) {
          context.addIssue({
            code: z.ZodIssueCode.too_big,
            type: 'number',
            inclusive: false,
            maximum: maxMemory,
            path: ['memory'],
            message:
              'Memory cannot be larger than amount of memory in node pool',
          });

          return z.NEVER;
        }
      })
      .superRefine(({ gpu, nodePoolName }, context) => {
        const nodePool = getNodePool(nodePools, nodePoolName)!;
        const { gpu: maxGpu } = getGpuFromNodePool(nodePool);

        if (!maxGpu) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['nodePoolName'],
            message: 'Number of GPUs is missing for this node pool',
          });

          return z.NEVER;
        }

        if (!gpu) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['gpu'],
            message: 'Number of GPUs cannot be 0 or blank',
          });

          return z.NEVER;
        }

        if (gpu > maxGpu) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['gpu'],
            message: 'GPUs limit exceeded for this node pool',
          });

          return z.NEVER;
        }
      })
      .superRefine(({ cpu, nodePoolName }, context) => {
        const MIN_CPU = 0.01;
        const { cpu: maxCpu } = getNodePool(nodePools, nodePoolName)!;

        if (!cpu) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['cpu'],
            message: 'Number of CPU cannot be blank',
          });

          return z.NEVER;
        }

        if (cpu < MIN_CPU) {
          context.addIssue({
            code: z.ZodIssueCode.too_small,
            type: 'number',
            inclusive: false,
            minimum: MIN_CPU,
            path: ['cpu'],
            message: `Number of CPU cores cannot be less than ${MIN_CPU} cores`,
          });

          return z.NEVER;
        }

        if (cpu > maxCpu) {
          context.addIssue({
            code: z.ZodIssueCode.too_big,
            type: 'number',
            inclusive: false,
            maximum: maxCpu,
            path: ['cpu'],
            message: 'CPU limit exceeded for this node pool',
          });
        }
      })
  );
};

export const TextEmbeddingInferenceConstructorPage = () => {
  const APP_TYPE = DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE;

  const cluster = useSelector(clusterContextSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { resourcePoolTypes: nodePools } = as.c(cluster);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(makeSchema({ nodePools })),
    defaultValues: {
      imageTag: '1.2.3',
      args: [],
    },
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;

  const appName = DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE;
  const {
    name,
    title,
    tags = [],
    image,
  } = dedicatedApps.find(({ name }) => name === appName)!;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { presetName } = useResourcePresetName({ appName });

  const handleAppInstall = handleSubmit(
    async ({
      name,
      modelName,
      imageTag,
      cpu,
      gpu,
      memory,
      memoryPrefix,
      nodePoolName,
      ingressEnabled,
      args,
    }) => {
      try {
        setLoading(true);

        const isIngressEnabled = ingressEnabled ? 'True' : 'False';
        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;

        const appCommand = new AppCommand();

        args.forEach(({ value }) => {
          appCommand.arg(value);
        });

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/app-text-embeddings-inference ${APP_TYPE} ${formattedName} charts/app-text-embedding-inference --timeout 15m0s`,
          )
          .set('ingress.enabled', isIngressEnabled)
          .set('ingress.clusterName', clusterName!)
          .set('llm.modelHFName', modelName)
          .set('image.tag', imageTag)
          .set('resources.requests.cpu', cpu)
          .set('resources.requests.memory', `${memory}${memoryPrefix}`)
          .set('resources.requests.nvidia\\.com/gpu', gpu)
          .set('resources.limits.nvidia\\.com/gpu', gpu)
          .set(
            'nodeSelector.platform\\.neuromation\\.io/nodepool',
            nodePoolName,
          )
          .compose();

        await createJob({
          organizationName,
          clusterName: clusterName!,
          projectName: projectName!,
          command,
          presetName,
          image,
          name: formattedName,
          passConfig: true,
          tags: [...staticTags, ...tags],
          env: {},
          secretEnv: {},
          volumes: [],
        });

        toast.success(`Installing ${title} App`);

        navigate(PATH.APPS, { replace: true });
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <Layout.EmptyContent
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </Layout.EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <JobConstructorNavigator navigator={appNavigator} />
        <FormProvider {...methods}>
          <form
            className="flex flex-1 justify-center"
            onSubmit={handleAppInstall}
          >
            <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
              <JobConstructorSection {...appNavigator.resources} number={1}>
                <Field.Input
                  {...register('modelName')}
                  required
                  label="Model Name"
                  error={errors.modelName}
                />
                <Field.Input
                  {...register('imageTag')}
                  required
                  label="Image Tag"
                  error={errors.imageTag}
                />
                <AppNodePoolField error={errors.nodePoolName} />

                <Field.Checkbox {...register('ingressEnabled')}>
                  Ingress Enabled
                </Field.Checkbox>

                <AppConstructorArguments />
                <div className="flex gap-3">
                  <Field.Input
                    {...register('cpu')}
                    required
                    inputMode="numeric"
                    type="number"
                    label="CPU"
                    note="Number of vCPU cores"
                    containerClassName="flex-1"
                    className="w-full"
                    error={errors.cpu}
                  />
                  <Field.Input
                    required
                    {...register('gpu')}
                    label="GPU"
                    note="GPU Quantity"
                    inputMode="numeric"
                    type="number"
                    containerClassName="flex-1"
                    className="w-full"
                    error={errors.gpu}
                  />
                </div>
                <div className="flex gap-3">
                  <Field.Input
                    {...register('memory', {
                      setValueAs: transformNumberFormat,
                    })}
                    required
                    label="Memory"
                    note="Amount of RAM to allocate"
                    containerClassName="flex-1"
                    error={errors.memory}
                  />
                  <Field.Select
                    {...register('memoryPrefix')}
                    containerClassName="basis-[80px]"
                  >
                    <option value="Gi">Gi</option>
                    <option value="Mi">Mi</option>
                  </Field.Select>
                </div>
              </JobConstructorSection>
              <JobConstructorSection {...appNavigator.metadata} number={2}>
                <Field.Input
                  {...register('name')}
                  label="Name"
                  className="w-full"
                  note="App name"
                  error={errors.name}
                />
              </JobConstructorSection>
              <Modal.Footer sticky className="-mt-10 px-0">
                <Button
                  type="submit"
                  loading={loading}
                  className="px-10 capitalize"
                >
                  Install app
                </Button>
              </Modal.Footer>
            </Theme.Container>
          </form>
        </FormProvider>
      </Layout.Content>
    </Layout>
  );
};
