import { useAuth0 } from '@auth0/auth0-react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { PATH } from '@constants';
import { useContextParamsTracker } from '@hooks';

import { Button, Icon, Link, Render, Theme } from '@components';
import onboardingBackground from '@images/onboarding-background.png';

type Props = {
  isOnboarding?: boolean;
  clusterTracker?: boolean;
  children?: React.ReactNode;
};

export const LayoutOnboarding = ({
  isOnboarding = false,
  clusterTracker,
  children,
}: Props) => {
  const { logout } = useAuth0();

  useContextParamsTracker({ cluster: clusterTracker });

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="relative flex h-full flex-1 bg-rebecca font-inter text-body">
      <img
        src={onboardingBackground}
        className="absolute bottom-0 right-0"
        alt=""
      />
      <div className="relative flex basis-3/4 flex-col rounded-r-[80px] border-b border-neural-04 bg-white px-16 py-5">
        <header className="flex items-center gap-4">
          <Render if={!isOnboarding}>
            <Link variant="ghost" to={PATH.HOME}>
              <Icon icon={faArrowLeft} className="text-[20px]" />
            </Link>
          </Render>
          <Link theme to={PATH.HOME}>
            <Theme.Logo className="h-auto w-32 text-black" />
          </Link>
          <Render if={isOnboarding}>
            <Button variant="ghost" onClick={handleLogout} className="ml-auto">
              Log out
            </Button>
          </Render>
        </header>
        {children}
      </div>
    </div>
  );
};
