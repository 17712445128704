import { Hostname } from '@typings';
import { useHostname } from '@hooks/useHostname';

import {
  ImhpcLogo,
  Logo,
  MegaportLogo,
  NovoserveLogo,
  ScottdcLogo,
  WwtLogo,
} from '@components/Ui';

type Props = {
  className?: string;
};

export const ThemeLogo = ({ className }: Props) => {
  const { title, checkHostname } = useHostname();

  const isBrandingLogo = title !== 'Apolo';

  const getLogoComponent = () => {
    if (checkHostname(Hostname.Scottdc)) {
      return ScottdcLogo;
    }

    if (checkHostname(Hostname.Novoserve)) {
      return NovoserveLogo;
    }

    if (checkHostname(Hostname.Imhpc)) {
      return ImhpcLogo;
    }

    if (checkHostname(Hostname.Megaport)) {
      return MegaportLogo;
    }

    if (checkHostname(Hostname.Wwt)) {
      return WwtLogo;
    }

    return Logo;
  };

  const LogoComponent = getLogoComponent();

  if (isBrandingLogo) {
    return (
      <div className="group flex flex-col items-start">
        <LogoComponent className={className} />
        <div className="group-hover::grayscale-0 flex items-center gap-1 text-body-small text-neural-03">
          <p className="whitespace-nowrap">Powered by</p>
          <Logo className="h-4 w-auto flex-initial text-neural-03 grayscale transition-all group-hover:text-primary group-hover:grayscale-0" />
        </div>
      </div>
    );
  }

  return <LogoComponent className={className} />;
};
