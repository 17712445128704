import { useEffect, useRef, useState } from 'react';
import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { AsyncFunction, ModalProps, TenantRole } from '@typings';
import { axios, toast } from '@features';
import { inviteOrganizationUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { capitalize, noop, toastifyResponseError } from '@utils';

import { Button, Field, Icon, Modal } from '@components';

type User = {
  name: string;
  email: string;
};

type Props = ModalProps & {
  getUsers: AsyncFunction;
};

export const OrganizationInviteMemberModal = ({
  getUsers,
  closeModal = noop,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { organizationName } = useSelector(contextNamesSelector);

  const roleRef = useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [input, setInput] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await axios.get<never, User[]>(`${adminUrl}/users`, {
          params: { lookup: true },
        });

        setUsers(users);
      } catch (error) {
        toast.error('Could not fetch user list');
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, [adminUrl]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setUsername('');
  };

  const handleMemberInvite = async () => {
    try {
      setLoading(true);

      const role = roleRef.current?.value as TenantRole;

      await inviteOrganizationUser({
        adminUrl,
        organizationName,
        username,
        role,
      });
      await getUsers();

      toast.success(
        `User ${username} added to ${organizationName} organization`,
      );

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const roleOptions = Object.values(TenantRole).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  const filterUser = ({ name, email }: User): boolean =>
    [name, email].some((field) => field.startsWith(input));

  const makeUser = ({ name, email }: User) => {
    const isFilteredByName = name.startsWith(input);
    const icon = isFilteredByName ? faUser : faEnvelope;
    const isSelected = username === name;

    return (
      <Button
        key={name}
        theme
        className={clsx(
          'flex items-center gap-3 rounded-md p-2 transition-colors hover:bg-background',
          { 'bg-background': isSelected },
        )}
        onClick={() => setUsername(name)}
      >
        <Icon
          icon={icon}
          className={clsx(
            'h-10 w-10 overflow-hidden rounded-full bg-stroke text-neural-04',
            { 'bg-primary-light text-white': isSelected },
          )}
        />
        <div className="text-left">
          <p>{name}</p>
          <p className="text-caption text-neural-04 transition-colors">
            {email}
          </p>
        </div>
      </Button>
    );
  };

  const renderUsers = () => {
    const filteredUsers = users.filter(filterUser);

    if (input.length < 2) {
      return null;
    }

    if (!filteredUsers.length) {
      return (
        <p className="mt-8 text-center text-neural-04">
          No users found on platform
        </p>
      );
    }

    return filteredUsers.map(makeUser);
  };

  return (
    <Modal.Content title="Invite Member" className="w-[578px]">
      <p className="text-footnote text-neural-04">
        Invite user to
        <span className="space-both text-body text-black">
          {organizationName}
        </span>
        organization
      </p>
      <div className="mt-3 flex gap-4">
        <Field.Input
          value={input}
          containerClassName="flex-1"
          label="Username / Email"
          note="Search by name or email"
          onChange={handleUsernameChange}
        />
        <Field.Select
          ref={roleRef}
          label="Role"
          options={roleOptions}
          containerClassName="basis-[140px]"
        />
      </div>
      <div className="mt-6 flex h-[200px] flex-col gap-4 overflow-auto">
        {renderUsers()}
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!username}
          loading={loading}
          className="px-14"
          onClick={handleMemberInvite}
        >
          Invite
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
