import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { Any, Flow } from '@typings';
import { PATH } from '@constants';
import { getFlowBakes, killBake } from '@services';
import { useFetchList, useHelmetTitle } from '@hooks';
import { as, formatToCamelCase, path } from '@utils';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Link,
  Render,
  Table,
} from '@components';
import { Layout } from '@components/Layouts';
import { EmptyList, Job } from '@components/Ui';

export const FlowBakesPage = () => {
  const { projectId, flowName } = useParams();
  const { makeTitle } = useHelmetTitle();
  const { isEmpty, isFetched, list, getList } = useFetchList<Flow.Bake[]>({
    fetchOnMount: false,
    fetchOnFilterChange: false,
    getList: getFlowBakes,
  });

  const [killingBakeId, setKillingBakeId] = useState('');

  /**
   * Bake is unformatted actually
   */
  const formattedList = list.map((bake) => {
    const configsMeta = (bake as Any).last_attempt
      .configs_meta as Flow.BakeLastAttempt['configsMeta'];
    const formattedBake = formatToCamelCase(bake) as Flow.Bake;
    formattedBake.lastAttempt.configsMeta = configsMeta;

    return formattedBake;
  });

  const [search, setSearch] = useState('');

  const getBakes = useCallback(async () => {
    getList({ projectId });
  }, [projectId, getList]);

  useEffect(() => {
    getBakes();
  }, [getBakes]);

  const handleKillBake = async (lastAttempt: Flow.BakeLastAttempt) => {
    try {
      const { bakeId } = lastAttempt;

      setKillingBakeId(bakeId);

      await killBake({ lastAttempt });
      await getBakes();
    } catch (error) {
      /**
       * Continue regardless error
       */
    } finally {
      setKillingBakeId('');
    }
  };

  const makeBake = ({
    id,
    name,
    batch,
    tags,
    lastAttempt,
    lastAttempt: { executorId, createdAt, result: status },
  }: Flow.Bake) => {
    const searchParams = new URLSearchParams({
      projectId: projectId!,
      flowName: flowName!,
    });
    const executorUrl = `${executorId}?${searchParams.toString()}`;
    const isKilling = killingBakeId === id;
    const isKillable = ['running', 'pending'].includes(status);

    return (
      <Table.Row key={id}>
        <Table.Cell>{batch}</Table.Cell>
        <Table.Cell>
          <Link
            theme
            className="truncate underline"
            to={path.bake(projectId, flowName, id, name as string)}
          >
            {id}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Render if={executorUrl}>
            <Link
              theme
              className="truncate underline"
              to={path.job(executorUrl)}
            >
              {executorId}
            </Link>
          </Render>
          <Render if={!executorId}>-</Render>
        </Table.Cell>
        <Table.Cell>
          <Job.Status status={status} createdAt={createdAt} />
        </Table.Cell>
        <Table.Cell>
          <Job.Tags tags={tags} />
        </Table.Cell>
        <Table.Cell className="flex-row justify-start gap-2">
          <Button
            loading={isKilling}
            disabled={!isKillable}
            variant="error"
            className="h-auto rounded-md py-1 text-footnote-large"
            onClick={() => handleKillBake(lastAttempt)}
          >
            Kill
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const filteredList = search
      ? formattedList.filter(
          ({ name, batch, id, lastAttempt: { executorId } }) =>
            [as<string>(name, ''), batch, id, as<string>(executorId, '')].some(
              (field: string) => field.includes(search),
            ),
        )
      : formattedList;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyList className="mt-4" title="No bakes are found in this flow" />
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(5,minmax(max-content,auto))_max-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Bake</Table.Head>
            <Table.Head>ID</Table.Head>
            <Table.Head>Executor ID</Table.Head>
            <Table.Head>Status</Table.Head>
            <Table.Head>Tags</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{filteredList.map(makeBake)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout>
      <Helmet title={makeTitle('Bakes', 'Flows', '%p', '%c')} />
      <div slot="header" className="flex min-w-0 items-center gap-4">
        <Link
          variant="ghost"
          to={PATH.FLOWS}
          className="h-auto p-0 text-[24px] text-neural-03"
        >
          <Icon icon={faChevronLeft} className="h-10 w-10" />
        </Link>
        <h3 className="truncate text-h4 text-white">Bakes of {flowName}</h3>
      </div>
      <Layout.Content>
        <div className="mb-14 flex items-center gap-10">
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <div className="flex items-start gap-10">{renderContent()}</div>
      </Layout.Content>
    </Layout>
  );
};
