import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { useJobConstructor } from '@hooks/job';
import { cn } from '@utils';

import { Button, Field, Icon, Modal, Render } from '@components';

import { JobConstructorPresetsModal } from '../Modals';

type Props = {
  error?: string;
};

export const JobPresetField = ({ error }: Props) => {
  const { resourcePresets } = useJobConstructor();

  const { register, watch, setValue } = useFormContext();

  const presetName: string = watch('presetName');

  useEffect(() => {
    register('presetName');
  }, [register]);

  const handleResourcePresetChange = (presetName: string) => {
    setValue('presetName', presetName);
  };

  return (
    <>
      <Modal
        content={
          <JobConstructorPresetsModal
            resourcePresets={resourcePresets}
            onApply={handleResourcePresetChange}
          />
        }
      >
        <Button
          theme
          className={cn(
            'relative flex h-14 items-center gap-2.5 rounded-lg border border-transparent bg-neural-01 px-4 py-2 transition-colors hover:border-neural-03',
            { '!border-error': !!error },
          )}
        >
          <Icon icon={faMicrochip} className="text-[22px]" />
          <div className="text-left">
            <p
              className={clsx('required-label text-neural-03', {
                'text-caption': !!presetName,
              })}
            >
              Preset
            </p>
            <Render if={presetName}>
              <p>{presetName}</p>
            </Render>
          </div>
          <Icon
            icon={faCaretDown}
            className="pointer-events-none absolute inset-y-0 right-4 m-auto text-neural-03"
          />
        </Button>
      </Modal>
      <Field.Error variant="static" className="-mt-4">
        {error}
      </Field.Error>
    </>
  );
};
