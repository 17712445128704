import { Cluster, ClusterSettings } from '@typings';
import { SUPPORTED_GPU_MODELS } from '@constants';

type Resource = Pick<
  Cluster.ResourcePoolType,
  'amdGpu' | 'intelGpu' | 'nvidiaGpu'
>;

type GpuModel = Partial<{
  gpu: number;
  gpuModel: (typeof SUPPORTED_GPU_MODELS)[number];
}>;

export const getGpu = (
  resource:
    | Cluster.ResourcePreset
    | ClusterSettings.NodePool
    | Cluster.ResourcePoolType,
): GpuModel => {
  return SUPPORTED_GPU_MODELS.reduce(
    (accumulator, gpuModel) => {
      const fieldName = `${gpuModel}Gpu`;
      const gpu = resource[fieldName as keyof Resource];

      if (gpu) {
        return { gpu, gpuModel };
      }

      return accumulator;
    },
    { gpu: undefined, gpuModel: undefined } as GpuModel,
  );
};

export const getGpuFromNodePool = (
  nodePool: ClusterSettings.NodePool | Cluster.ResourcePoolType,
) => getGpu(nodePool);
export const getGpuFromResourcePreset = (
  resourcePreset: Cluster.ResourcePreset,
) => getGpu(resourcePreset);

export const parseGpuToPreset = () => {};
