import { Layout } from '@components/Layouts';
import { OnboardingProject } from '@components/Onboarding';

export const CreateProjectPage = () => {
  return (
    <Layout.Onboarding clusterTracker>
      <div className="flex flex-1 items-center">
        <OnboardingProject />
      </div>
    </Layout.Onboarding>
  );
};
