import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Any,
  AsyncFunction,
  Cluster,
  ClusterSettings,
  ModalProps,
} from '@typings';
import {
  createClusterResourcePreset,
  updateClusterResourcePreset,
} from '@services';
import { setContext } from '@slices';
import { getConfig } from '@thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  as,
  formatModelName,
  getFormattedMemory,
  getGpuFromNodePool,
  getGpuFromResourcePreset,
  getParsedMemory,
  isString,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { gpuFormattedName } from '@content';

import { Button, Field, Modal, Render } from '@components';
import { ClusterResourcePresetChips } from '@components/Ui';

type Schema = z.infer<ReturnType<typeof makeSchema>>;

type SchemaParams = {
  nodePools: ClusterSettings.NodePool[];
};

const transformNumberFormat = (value: unknown) =>
  isString(value) ? value.replace(',', '.') : value;

export const checkGPU = (
  { gpu = 0, gpuModel }: Partial<{ gpu: number; gpuModel: string }>,
  nodePool: ClusterSettings.NodePool,
) => {
  const { gpu: nodePoolGpu, gpuModel: nodePoolGpuModel } =
    getGpuFromNodePool(nodePool);

  /**
   * Schedule only GPU jobs on GPU nodes
   */
  if (gpu) {
    /**
     * First operand totally includes `nodePoolGpu` string value if truthy
     */
    return gpuModel === nodePoolGpuModel && gpu <= nodePoolGpu!;
  }

  /**
   * Schedule CPU jobs on CPU nodes
   */
  return (!gpu || !gpuModel) && nodePoolGpu;
};

const makeSchema = ({ nodePools }: SchemaParams) => {
  const MIN_CPU = 0.01;
  const maxCpu = Math.max(...nodePools.map(({ cpu }) => cpu));
  const MIN_MEMORY = 64 * 10 ** 6;
  const maxMemory = Math.max(...nodePools.map(({ memory }) => memory));
  const maxGpus = nodePools.reduce(
    (gpus, nodePool) => {
      const { gpu, gpuModel } = getGpuFromNodePool(nodePool);

      if (!gpu || !gpuModel) {
        return gpus;
      }

      const gpusValue = gpus[gpuModel];

      return gpusValue > gpu ? gpus : { ...gpus, [gpuModel]: gpu };
    },
    {} as { [K: string]: number },
  );

  return z
    .object({
      name: z.string().min(1),
      cpu: z.coerce.number().superRefine((cpu: number, context) => {
        if (cpu < MIN_CPU) {
          context.addIssue({
            code: z.ZodIssueCode.too_small,
            type: 'number',
            inclusive: false,
            minimum: MIN_CPU,
            message: `Number of CPU cores cannot be less than ${MIN_CPU} cores`,
          });

          return z.NEVER;
        }

        if (cpu > maxCpu) {
          context.addIssue({
            code: z.ZodIssueCode.too_big,
            type: 'number',
            inclusive: false,
            maximum: maxCpu,
            message:
              'Number of CPU cores cannot be larger than number of cores in any node pool',
          });
        }
      }),
      gpu: z.coerce.number().optional(),
      gpuModel: z.string().optional(),
      creditsPerHour: z.coerce.number().int().nonnegative(),
      memory: z.coerce.number(),
      memoryPrefix: z.string(),
      isPreemptible: z.boolean(),
      isSchedulerEnabled: z.boolean().optional(),
      resourcePoolNames: z
        .object({ name: z.string(), value: z.boolean() })
        .array(),
    })
    .superRefine(({ memory, memoryPrefix }, context) => {
      const parsedMemory = getParsedMemory(`${memory}${memoryPrefix}`) ?? 0;
      const isMemoryNaN = Number.isNaN(memory);

      if (isMemoryNaN) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['memory'],
          message: 'Memory is not a number',
        });

        return z.NEVER;
      }

      if (!parsedMemory) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['memory'],
          message: 'Memory cannot be blank',
        });

        return z.NEVER;
      }

      if (parsedMemory < MIN_MEMORY) {
        context.addIssue({
          code: z.ZodIssueCode.too_small,
          type: 'number',
          inclusive: false,
          minimum: MIN_MEMORY,
          path: ['memory'],
          message: `Memory cannot be less than ${getFormattedMemory(
            MIN_MEMORY,
          )}`,
        });

        return z.NEVER;
      }

      if (parsedMemory > maxMemory) {
        context.addIssue({
          code: z.ZodIssueCode.too_big,
          type: 'number',
          inclusive: false,
          maximum: maxMemory,
          path: ['memory'],
          message:
            'Memory cannot be larger than amount of memory in any node pool',
        });

        return z.NEVER;
      }
    })
    .superRefine(({ gpu, gpuModel }, context) => {
      if (gpu && !gpuModel) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['gpuModel'],
          message: 'GPU model should be selected when GPU quantity set',
        });

        return z.NEVER;
      }

      if (gpu && gpu > maxGpus[gpuModel as string]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['gpuModel'],
          message:
            'Number of GPUs cannot be larger than number of this GPUs in any node pools',
        });

        return z.NEVER;
      }

      if (gpuModel && !gpu) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['gpuModel'],
          message: 'Number of GPUs cannot be 0 for selected GPU model',
        });

        return z.NEVER;
      }

      return z.NEVER;
    })
    .refine(
      ({ cpu, memory, isPreemptible }) =>
        nodePools.some((nodePool) => {
          return (
            cpu <= nodePool.availableCpu &&
            memory <= nodePool.availableMemory &&
            isPreemptible === nodePool.isPreemptible
          );
        }),
      {
        message: "This preset won't fit into any node pool",
        path: ['name'],
      },
    );
};

/**
 * todo: fix type
 */
type ResourcePreset = Any;

type Props = ModalProps & {
  edit?: boolean;
  name?: string;
  resourcePreset?: Cluster.ResourcePreset;
  nodePools: ClusterSettings.NodePool[];
  getCluster: AsyncFunction;
};

export const ClusterResourcePresetModal = ({
  edit = false,
  name,
  nodePools,
  resourcePreset,
  getCluster,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { clusterName } = useSelector(contextNamesSelector);

  const getDefaultValues = (): ResourcePreset | undefined => {
    const {
      isPreemptible,
      schedulerEnabled,
      name,
      cpu,
      memory,
      creditsPerHour,
      resourcePoolNames = [],
    } = as.o<Cluster.ResourcePreset>(resourcePreset);

    const defaultResourcePoolNames = nodePools.map(({ name }) => ({
      name,
      value: resourcePoolNames.includes(name),
    }));

    if (!resourcePreset) {
      return { resourcePoolNames: defaultResourcePoolNames };
    }

    const { gpu, gpuModel } = getGpuFromResourcePreset(resourcePreset);
    const formattedMemory = getFormattedMemory(memory, {
      separator: ' ',
      fix: 0,
    });
    const [memoryValue, memoryPrefix] = formattedMemory.split(' ');

    return {
      name,
      memory: memoryValue,
      memoryPrefix,
      gpu,
      gpuModel,
      cpu,
      isSchedulerEnabled: schedulerEnabled,
      isPreemptible,
      creditsPerHour,
      resourcePoolNames: defaultResourcePoolNames,
    };
  };

  const methods = useForm<Schema>({
    resolver: zodResolver(makeSchema({ nodePools })),
    defaultValues: getDefaultValues(),
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);
  const gpuModels = nodePools.reduce((models, nodePool) => {
    const { gpuModel } = getGpuFromNodePool(nodePool);

    return !gpuModel || models.includes(gpuModel)
      ? models
      : [...models, gpuModel];
  }, [] as string[]);

  const handleFormSubmit = handleSubmit(
    async ({
      name: newName,
      cpu,
      gpu,
      gpuModel,
      memory,
      memoryPrefix,
      isSchedulerEnabled,
      isPreemptible,
      creditsPerHour,
      resourcePoolNames,
    }) => {
      try {
        setLoading(true);

        const formattedName = formatModelName(newName);
        const parsedMemory = getParsedMemory(`${memory}${memoryPrefix}`) ?? 0;
        const formattedResourcePoolNames = resourcePoolNames
          .filter(({ value }) => value)
          .map(({ name }) => name);
        const gpuType = gpuModel ? { [`${gpuModel}Gpu`]: gpu } : undefined;
        const payload = {
          ...gpuType,
          clusterName: clusterName!,
          name: formattedName,
          memory: parsedMemory,
          cpu,
          isPreemptible,
          isSchedulerEnabled: isSchedulerEnabled ?? false,
          creditsPerHour,
          resourcePoolNames: formattedResourcePoolNames,
        };

        if (edit) {
          await updateClusterResourcePreset(name as string, payload);
        } else {
          await createClusterResourcePreset(payload);
        }

        await getCluster();
        const { clusters } = await dispatch(getConfig());

        const cluster = clusters.find(({ name }) => name === clusterName);

        if (cluster) {
          dispatch(setContext({ cluster }));
        }

        closeModal();
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const makeGpuModelOption = (gpuModel: string) => (
    <option key={gpuModel} value={gpuModel}>
      {/* @ts-ignore */}
      {gpuFormattedName[gpuModel] ?? gpuModel}
    </option>
  );

  return (
    <Modal.Content className="w-[578px]">
      <Modal.Header sticky className="capitalize">
        {edit ? `Edit preset ${name}` : 'Create resouce preset'}
      </Modal.Header>
      <FormProvider {...methods}>
        <form
          noValidate
          className="flex flex-col gap-6"
          onSubmit={handleFormSubmit}
        >
          <Field.Input
            {...register('name')}
            required
            label="Name"
            note="Name should uniqly identify resource preset within cluster"
            className="w-full"
            error={errors.name}
          />
          <Field.Input
            {...register('cpu', { setValueAs: transformNumberFormat })}
            required
            type="number"
            label="Number of vCPU cores"
            note="Amount of virtual CPU cores to allocate"
            className="w-full"
            error={errors.cpu}
          />
          <div className="flex gap-3">
            <Field.Input
              {...register('memory', { setValueAs: transformNumberFormat })}
              required
              label="Amount of RAM"
              note="Amount of RAM to allocate"
              className="w-full"
              containerClassName="flex-1"
              error={errors.memory}
            />
            <Field.Select
              {...register('memoryPrefix')}
              containerClassName="basis-[80px]"
            >
              <option value="MB">MB</option>
              <option value="GB">GB</option>
              <option value="TB">TB</option>
            </Field.Select>
          </div>
          <div className="flex gap-3">
            <Field.Select
              {...register('gpuModel')}
              label="GPU Model"
              note="Type and number of GPUs to allocate"
              containerClassName="flex-1"
              error={errors.gpuModel || errors.gpu}
            >
              <option value="">None</option>
              {gpuModels.map(makeGpuModelOption)}
            </Field.Select>
            <Field.Input
              {...register('gpu')}
              label="GPU Quantity"
              className="w-full"
              containerClassName="basis-[120px]"
            />
          </div>
          <Render if={nodePools}>
            <div>
              <Field.Label className="capitalize text-neural-04">
                Resource pool names
              </Field.Label>
              <ClusterResourcePresetChips />
            </div>
          </Render>
          <Field.Checkbox
            {...register('isSchedulerEnabled')}
            note="Enables suspending and rescheduling of jobs by the platform"
          >
            Round-robin
          </Field.Checkbox>
          <Field.Checkbox
            {...register('isPreemptible')}
            note="Marks that this preset requires preemtible node"
          >
            Preemptability
          </Field.Checkbox>
          <Field.Input
            {...register('creditsPerHour')}
            type="number"
            label="Price for hour"
            note="The cost of using this preset for 1 hour in credits"
            className="w-full"
            error={errors.creditsPerHour}
          />
        </form>
      </FormProvider>
      <Modal.Footer sticky className="items-center justify-between bg-white">
        <p className="text-neural-03">
          <span className="text-error">*</span> Required
        </p>
        <Button className="px-10" loading={loading} onClick={handleFormSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
