import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  faPenToSquare,
  faTrash,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings, TenantRole } from '@typings';
import { getOrganizationUser, getOrganizationUsers } from '@services';
import { configSelector, contextNamesSelector, userSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';
import { settingsTabs } from '@content';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Modal,
  Table,
} from '@components';
import { ClusterSettingsTabs } from '@components/Cluster';
import { Layout } from '@components/Layouts';
import {
  OrganizationEditUserModal,
  OrganizationInviteMemberModal,
  OrganizationRemoveUserModal,
} from '@components/Organization';

export const OrganizationUsersSettingsPage = () => {
  const { adminUrl } = useSelector(configSelector);
  const { username } = useSelector(userSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  const {
    list: users,
    isFetched,
    getList,
  } = useFetchList<ClusterSettings.User[]>({
    getList: getOrganizationUsers,
    fetchOnMount: false,
  });
  const { makeTitle } = useHelmetTitle();

  const [search, setSearch] = useState('');
  const [tenantRole, setTenantRole] = useState(TenantRole.User);

  const isManager = [TenantRole.Admin, TenantRole.Manager].includes(tenantRole);

  const getUsers = useCallback(async () => {
    getList({ adminUrl, organizationName });
  }, [adminUrl, organizationName, getList]);

  const getTenantRole = useCallback(async () => {
    try {
      const { role } = await getOrganizationUser({
        organizationName,
        username,
      });

      setTenantRole(role);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  }, [username, organizationName]);

  useEffect(() => {
    if (clusterName) {
      getTenantRole();
    }
  }, [clusterName, getUsers, getTenantRole]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const makeItem = ({
    role,
    userName,
    userInfo: { email, createdAt },
  }: ClusterSettings.User) => {
    const date = createdAt && dayjs(createdAt).format('DD.MM.YYYY');

    return (
      <Table.Row key={email}>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell className="flex-row">
          <div className="flex gap-4">
            <Modal
              disabled={!isManager}
              content={
                <OrganizationEditUserModal
                  username={userName}
                  role={role}
                  getUsers={getUsers}
                />
              }
            >
              <Button variant="ghost" className="h-6 w-6 p-0 text-[18px]">
                <Icon icon={faPenToSquare} />
              </Button>
            </Modal>
            <Modal
              disabled={userName === username || !isManager}
              content={
                <OrganizationRemoveUserModal
                  username={userName}
                  getUsers={getUsers}
                />
              }
            >
              <Button variant="ghost-error" className="h-6 w-6 p-0 text-[18px]">
                <Icon icon={faTrash} />
              </Button>
            </Modal>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? users.filter(
          ({ userName, userInfo: { email } }) =>
            userName.includes(search) || email.includes(search),
        )
      : users;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(4,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>Role</Table.Head>
            <Table.Head>Email</Table.Head>
            <Table.Head>Sign up Date</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{list.map(makeItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout
      title={`${organizationName!} - Organization Settings`}
      isProjectReffered={false}
      clusterContextTracker={false}
      projectContextTracker={false}
    >
      <Helmet
        title={makeTitle('Users', 'Organization Settings', '%o')}
        description="Invite new members and manage access permissions within your organization. Streamline the onboarding process and ensure your team is ready to collaborate effectively"
      />
      <Layout.Content>
        <ClusterSettingsTabs tabs={settingsTabs.organization} />

        <div className="flex flex-col gap-8">
          <div className="flex gap-10">
            <Modal
              disabled={!isManager}
              content={<OrganizationInviteMemberModal getUsers={getUsers} />}
            >
              <Button variant="rebecca" className="gap-3 capitalize">
                <Icon icon={faUserPlus} />
                Invite people
              </Button>
            </Modal>
            <Field.Input
              containerClassName="flex-1"
              className="border-neural-03"
              label="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          {renderContent()}
        </div>
      </Layout.Content>
    </Layout>
  );
};
