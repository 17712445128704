import { MouseEventHandler } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { cn, VariantProps } from '@utils';
import { buttonVariants } from '@utils/variants';

type Props = VariantProps<typeof buttonVariants> & {
  to: To;
  theme?: boolean;
  disabled?: boolean;
  className?: string;
  external?: boolean;
  blank?: boolean;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const Link = ({
  to,
  children,
  theme,
  disabled = false,
  className,
  variant,
  external = false,
  blank = false,
  ...props
}: Props) => {
  const target = blank ? '_blank' : '';
  const classNames = theme
    ? className
    : cn(buttonVariants({ variant, className }), {
        'pointer-events-none': disabled,
      });
  const options = {
    ...props,
    className: classNames,
    to,
    target,
    disabled,
  };

  if (external) {
    const linkOptions = {
      ...props,
      className: classNames,
      target,
      href: to as string,
      disabled,
    };

    return (
      // eslint-disable-next-line react/no-invalid-html-attribute
      <a {...linkOptions} rel="noreferrer nooperer">
        {children}
      </a>
    );
  }

  return <RouterLink {...options}>{children}</RouterLink>;
};
