import { ChaseSpinner } from '@components';
import { Layout } from '@components/Layouts';

export const LoaderPage = () => {
  return (
    <Layout showFeatures={false} className="flex justify-center">
      <Layout.EmptyContent>
        <ChaseSpinner color="primary" className="h-10 w-10" />
      </Layout.EmptyContent>
    </Layout>
  );
};
