import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

import { SUPPORT_EMAIL } from '@constants';
import { useMailContext } from '@hooks/useMailContext';

import { Link } from '@components';
import { Layout } from '@components/Layouts';

export const FallbackError = () => {
  const { mailto } = useMailContext();

  return (
    <Layout.EmptyContent icon={faCircleXmark} title="Something went wrong">
      <p slot="text">
        For further assistance, please contact support at{' '}
        <Link
          external
          theme
          to={mailto}
          className="text-primary underline hover:no-underline"
        >
          {SUPPORT_EMAIL}
        </Link>
        .
      </p>
    </Layout.EmptyContent>
  );
};
