import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  faMicrochip,
  faRectangleTerminal,
} from '@fortawesome/pro-regular-svg-icons';

import { App, AsyncFunction, Job as JobType } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { toast } from '@features';
import { createJob, killRunningJob, uninstallLlmInferenceApp } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  viewTypeSelector,
} from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { path, toastifyResponseError } from '@utils';
import { builtItApps } from '@content';

import { Button, Helmet, Icon, Link, Render, Table } from '@components';
import { AppItem, Job } from '@components/Ui';
import {
  AppInstalledDedicatedItem,
  AppInstalledItem,
} from '@components/Ui/Apps';
import * as jobImage from '@images/jobs';

type Props = {
  runningJobs: JobType.Running[];
  installedDedicatedApps: App.DedicatedModel[];
  setTitle: (title: string) => void;
  getJobs: AsyncFunction;
};
export const AppsInstalledOutlet = ({
  runningJobs,
  installedDedicatedApps,
  setTitle,
  getJobs,
}: Props) => {
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);
  const { isGridView } = useSelector(viewTypeSelector);

  const { makeTitle } = useHelmetTitle();
  const { appName } = useParams();

  const [killingAppId, setKillingAppId] = useState('');

  const presetName = cluster?.resourcePresets[0]?.name;

  useEffect(() => {
    setTitle('Installed Apps');
  }, [setTitle]);

  const handleDedicatedAppUninstall = async (
    appName: string,
    fn: AsyncFunction,
  ) => {
    try {
      setKillingAppId(appName);

      await fn(appName);

      toast.success('The app is being uninstalled');
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setKillingAppId('');
    }
  };

  const handleJobKill = async (id: string) => {
    setKillingAppId(id);

    try {
      await killRunningJob(id);
      await getJobs();

      toast.success('Job is uninstalled');
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setKillingAppId('');
    }
  };

  const filterdedicatedApp = ({ targetName }: App.DedicatedModel) => {
    if (!appName) {
      return true;
    }

    return targetName === appName;
  };

  /**
   * App like a job
   */
  const filterApp = ({ targetName }: JobType.Running) => {
    const isDedicatedApp = Object.values(DEDICATED_APP_NAME).some(
      (name) => name === targetName,
    );

    return appName
      ? !isDedicatedApp && targetName === appName
      : !isDedicatedApp;
  };

  const makeDedicatedApp = ({
    name,
    status,
    targetName,
    jobId,
  }: App.DedicatedModel) => {
    const isUninstalling = killingAppId === name;
    // @ts-ignore
    const logo = jobImage[targetName];
    const uninstallMethod = {
      [DEDICATED_APP_NAME.LLM_INFERENCE]: uninstallLlmInferenceApp,
      [DEDICATED_APP_NAME.POSTGRESQL]: async () => {
        const entrypoint = `./entrypoints/pgo.sh delete ${DEDICATED_APP_NAME.POSTGRESQL} ${name}`;
        const image = 'ghcr.io/neuro-inc/app-deployment';

        if (!presetName) {
          return;
        }

        return createJob({
          presetName,
          entrypoint,
          image,
          passConfig: true,
          clusterName: clusterName!,
          organizationName,
          projectName: projectName!,
          env: {},
          secretEnv: {},
          volumes: [],
        });
      },
    };
    const onUninstall = () =>
      handleDedicatedAppUninstall(name, uninstallMethod[targetName]);

    if (isGridView) {
      return (
        <AppInstalledDedicatedItem
          key={jobId}
          isUninstalling={isUninstalling}
          appName={name}
          targetName={targetName}
          status={status}
          jobId={jobId}
          onAppUninstall={onUninstall}
        />
      );
    }

    return (
      <Table.Row key={appName}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-0.5 text-[12px] text-white">
            <img src={logo} alt="" />
          </div>
          <p className="truncate">{name}</p>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          <Job.Status status={status} />
        </Table.Cell>
        <Table.Cell />
        <Table.Cell className="items-end">
          <Button
            loading={isUninstalling}
            variant="error"
            className="h-8 rounded-md px-2 py-1 text-footnote-large"
            onClick={onUninstall}
          >
            Uninstall
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  const makeApp = (app: JobType.Running) => {
    const {
      id,
      name,
      history,
      tags = [],
      targetName,
      presetName,
      httpUrl,
      httpUrlNamed,
    } = app;
    // @ts-ignore
    const logo = jobImage[targetName];

    if (isGridView) {
      return (
        <AppInstalledItem
          {...app}
          killingAppId={killingAppId}
          onJobKill={handleJobKill}
        />
      );
    }

    return (
      <Table.Row key={id}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-2 text-[12px] text-white">
            <Render if={!logo}>
              <Icon icon={faRectangleTerminal} />
            </Render>
            <Render if={logo}>
              <img src={logo} alt="" />
            </Render>
          </div>
          <Link theme className="truncate underline" to={path.app(id)}>
            {name ?? id}
          </Link>
        </Table.Cell>
        <Table.Cell className="flex-row items-center gap-2">
          <Icon icon={faMicrochip} />
          {presetName}
        </Table.Cell>
        <Table.Cell>
          <Job.Status {...history} />
        </Table.Cell>
        <Table.Cell>
          <Job.Tags tags={tags} />
        </Table.Cell>
        <Table.Cell className="flex-row items-center">
          <div className="flex gap-2">
            <Link
              external
              blank
              to={httpUrlNamed || httpUrl}
              className="h-8 rounded-md px-2 py-1 text-footnote-large"
            >
              Open
            </Link>
            <Button
              variant="error"
              className="h-8 rounded-md px-2 py-1 text-footnote-large"
              onClick={() => handleJobKill(id)}
              loading={killingAppId === id}
            >
              Uninstall
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const makeBuiltInApp = ({ icon, name, path, tags }: App.BuiltInItem) => {
    if (isGridView) {
      return (
        <AppItem.BuiltIn
          key={path}
          icon={icon}
          name={name}
          path={path}
          tags={tags}
        />
      );
    }

    return (
      <Table.Row key={path}>
        <Table.Cell className="flex-row items-center justify-start gap-2">
          <div className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md bg-black p-2 text-[12px] text-white">
            <Icon icon={icon} />
          </div>
          <Link theme className="truncate underline" to={path}>
            {name}
          </Link>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>Installed</Table.Cell>
        <Table.Cell>
          <Job.Tags tags={tags} />
        </Table.Cell>
        <Table.Cell className="flex-row items-center justify-end">
          <Link
            to={path}
            className="h-8 rounded-md px-4 py-1 text-footnote-large"
          >
            Open
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (isGridView) {
    return (
      <>
        <Helmet title={makeTitle('Installed Apps', '%p', '%c')} />
        <div className="grid grid-cols-3 gap-x-10 gap-y-8 hdp:grid-cols-2">
          {installedDedicatedApps
            .filter(filterdedicatedApp)
            .map(makeDedicatedApp)}
          {runningJobs.filter(filterApp).map(makeApp)}
          <Render if={!appName}>{builtItApps.map(makeBuiltInApp)}</Render>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet title={makeTitle('Installed Apps', '%p', '%c')} />
      <Table className="w-full auto-rows-min grid-cols-[minmax(240px,auto)_repeat(3,max-content)_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>ID / Name</Table.Head>
            <Table.Head>Preset Name</Table.Head>
            <Table.Head>Status</Table.Head>
            <Table.Head>Tags</Table.Head>
            <Table.Head className="text-right">Actions</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {installedDedicatedApps
            .filter(filterdedicatedApp)
            .map(makeDedicatedApp)}
          {runningJobs.filter(filterApp).map(makeApp)}
          <Render if={!appName}>{builtItApps.map(makeBuiltInApp)}</Render>
        </Table.Body>
        <Table.Body />
      </Table>
    </>
  );
};
