import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { DEDICATED_APP_NAME, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { useResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { appNavigator, dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  AppConstructorUserDatabases,
  JobConstructorNavigator,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  postgresVersion: z.coerce.number().min(1).max(16),
  instanceReplicas: z.coerce.number().min(1).max(10),
  pgBouncerReplicas: z.coerce.number().min(1).max(10),
  presetName: z.string().min(1),
  name: z.string(),
  users: z
    .object({
      username: z.string(),
      databases: z.object({ name: z.string() }).array(),
    })
    .array(),
});

export const PostrgesqlConstructorPage = () => {
  const APP_TYPE = DEDICATED_APP_NAME.POSTGRESQL;

  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      postgresVersion: 16,
      instanceReplicas: 3,
      pgBouncerReplicas: 2,
    },
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit, setValue } = methods;

  const appName = DEDICATED_APP_NAME.POSTGRESQL;
  const {
    name,
    title,
    tags = [],
    image,
    jobResourcePreset,
  } = dedicatedApps.find(({ name }) => name === appName)!;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { presetName } = useResourcePresetName({
    appName,
    resourcePreset: jobResourcePreset,
  });

  useEffect(() => {
    if (presetName) {
      setValue('presetName', presetName);
    }
  }, [presetName, setValue]);

  const handleAppInstall = handleSubmit(
    async ({
      presetName,
      name,
      postgresVersion,
      instanceReplicas,
      pgBouncerReplicas,
      users,
    }) => {
      try {
        setLoading(true);

        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;

        const appCommand = new AppCommand();

        users
          .filter(({ username }) => username)
          .forEach(({ username, databases }, userIndex) => {
            const isDatabaseValid = databases.filter(({ name }) => name);

            if (!isDatabaseValid) {
              return;
            }

            appCommand.set(
              `users[${userIndex}].name`,
              formatModelName(username),
            );

            databases
              /**
               * Filters database names to keep correct index incrementation
               */
              .filter(({ name }) => name)
              .forEach(({ name }, databaseIndex) => {
                appCommand.set(
                  `users[${userIndex}].databases[${databaseIndex}]`,
                  formatModelName(name),
                );
              });
          });

        const entrypoint = appCommand
          .construct(
            `./entrypoints/pgo.sh install ${APP_TYPE} ${formattedName} --timeout 15m0s`,
          )
          .set('postgresVersion', postgresVersion)
          .set('instanceReplicas', instanceReplicas)
          .set('pgBouncerReplicas', pgBouncerReplicas)
          .compose();

        await createJob({
          organizationName,
          clusterName: clusterName!,
          projectName: projectName!,
          entrypoint,
          presetName,
          image,
          name: formattedName,
          passConfig: true,
          tags: [...staticTags, ...tags],
          env: {},
          secretEnv: {},
          volumes: [],
        });

        toast.success(`Installing ${title} App`);

        navigate(PATH.APPS, { replace: true });
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <Layout.EmptyContent
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </Layout.EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <JobConstructorNavigator navigator={appNavigator} />
        <FormProvider {...methods}>
          <form
            className="flex flex-1 justify-center"
            onSubmit={handleAppInstall}
          >
            <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
              <JobConstructorSection {...appNavigator.resources} number={1}>
                <JobPresetField error={errors.presetName} />
                <Field.Input
                  {...register('postgresVersion')}
                  required
                  label="Postgres Version"
                  type="number"
                  inputMode="numeric"
                  error={errors.postgresVersion}
                  note="From 1 to 16 inclusive"
                />
                <Field.Input
                  {...register('instanceReplicas')}
                  required
                  label="Instance Replicas"
                  type="number"
                  inputMode="numeric"
                  error={errors.instanceReplicas}
                  note="From 1 to 10 inclusive"
                />
                <Field.Input
                  {...register('pgBouncerReplicas')}
                  required
                  label="PgBouncer Replicas"
                  type="number"
                  inputMode="numeric"
                  error={errors.pgBouncerReplicas}
                  note="From 1 to 10 inclusive"
                />
                <AppConstructorUserDatabases />
              </JobConstructorSection>
              <JobConstructorSection {...appNavigator.metadata} number={2}>
                <Field.Input
                  {...register('name')}
                  label="Name"
                  className="w-full"
                  note="App name"
                  error={errors.name}
                />
              </JobConstructorSection>
              <Modal.Footer sticky className="-mt-10 px-0">
                <Button
                  type="submit"
                  loading={loading}
                  className="px-10 capitalize"
                >
                  Install app
                </Button>
              </Modal.Footer>
            </Theme.Container>
          </form>
        </FormProvider>
      </Layout.Content>
    </Layout>
  );
};
