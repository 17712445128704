import { faMicrochip, faXmark } from '@fortawesome/pro-regular-svg-icons';

import { AnyFunction, Job, Payload } from '@typings';
import { shareAccess } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, noop, path } from '@utils';

import { Badge, Button, Icon, Link, Modal, Render } from '@components';
import { SaveJobImageModal, ShareAccessModal } from '@components/Modals';

import { JobResource } from './JobResource';

type Props = {
  job?: Job.Model;
  isKilling?: boolean;
  onKill?: (jobId: string) => Promise<void>;
  onClose?: AnyFunction;
};

export const JobDetails = ({
  isKilling = false,
  job,
  onKill = noop,
  onClose = noop,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { metricsUrl = '', usersUrl } = as.c(cluster);

  const makeTag = (tag: string) => <Badge key={tag}>{tag}</Badge>;

  if (!job) {
    return null;
  }

  const {
    id: jobId,
    name,
    presetName,
    tags,
    status,
    container: {
      image,
      resources: { cpu, memoryMb },
    },
  } = job;
  const isJobRunning = status === 'running';
  const isJobKillable = isJobRunning || status === 'pending';

  const handleAccessShare = async ({
    permission,
    username,
  }: Payload.ShareAccess) => {
    const uri = path.create(
      'job:/',
      clusterName,
      organizationName,
      projectName,
      jobId,
    );

    await shareAccess({
      usersUrl,
      uri,
      username,
      action: permission,
    });
  };

  return (
    <div className="flex w-[320px] shrink-0 flex-col gap-10">
      <div className="flex flex-col gap-6">
        <div className="flex items-start justify-between">
          <h4 className="text-h6">Job Details</h4>
          <Button theme onClick={onClose}>
            <Icon icon={faXmark} />
          </Button>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-footnote text-neural-03">ID / Name</p>
          <p className="text-black">{name || jobId}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-footnote text-neural-03">Image</p>
          <p className="text-black">
            <Link
              external
              blank
              theme
              to={`https://${image}`}
              className="underline"
            >
              {image}
            </Link>
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-footnote text-neural-03">Preset</p>
          <div className="flex items-center gap-2">
            <Icon icon={faMicrochip} />
            <p>{presetName}</p>
          </div>
        </div>
      </div>

      <div>
        <h4 className="mb-6 text-h6">System Load</h4>
        <div className="flex gap-14">
          <JobResource title="CPU" value={cpu.toString()} />
          <JobResource title="RAM" value={`${memoryMb}MB`} />
        </div>
      </div>

      <div>
        <h4 className="mb-6 text-h6">Actions</h4>
        <div className="flex flex-wrap gap-4">
          <Modal
            disabled={!isJobRunning}
            content={<SaveJobImageModal jobId={jobId} />}
          >
            <Button disabled={!isJobRunning}>Save Image</Button>
          </Modal>
          <Link external blank to={path.metricsUrl(metricsUrl, jobId)}>
            Monitor
          </Link>
          <Modal
            content={
              <ShareAccessModal
                title={`Sharing job "${name || jobId}"`}
                onShare={handleAccessShare}
              />
            }
          >
            <Button className="capitalize">Share access</Button>
          </Modal>
          <Button
            variant="error"
            loading={isKilling}
            disabled={!isJobKillable}
            onClick={() => onKill(jobId)}
          >
            Kill
          </Button>
        </div>
      </div>

      <Render if={tags}>
        <div>
          <h4 className="mb-6 text-h6">Tags</h4>
          <div className="flex flex-wrap gap-1">{tags?.map(makeTag)}</div>
        </div>
      </Render>
    </div>
  );
};
