import { faExternalLink } from '@fortawesome/pro-thin-svg-icons';

import { Icon, Link } from '@components';

type Props = {
  title: string;
  to: string;
};

export const ClusterReportLink = ({ title, to }: Props) => {
  return (
    <Link
      theme
      external
      blank
      className="flex h-56 w-56 flex-col items-center justify-center gap-4 rounded-xl border border-neural-02 bg-white p-4 transition-colors hover:border-rebecca"
      to={to}
    >
      <Icon
        icon={faExternalLink}
        className="h-14 w-14 text-[48px] text-primary"
      />
      <p className="text-center text-h6 font-400 capitalize">{title}</p>
    </Link>
  );
};
