import { clusterContextSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { as } from '@utils';

import { Helmet } from '@components';
import { ClusterReportLink } from '@components/Ui';

type Props = {
  isManager: boolean;
};

export const ClusterSettingsReportsOutlet = ({ isManager }: Props) => {
  const cluster = useSelector(clusterContextSelector);

  const { makeTitle } = useHelmetTitle();

  const { metricsUrl } = as.c(cluster);

  const makeDashboardLink = (
    value: string,
    refreshTimeoutMinutes: string = '1m',
  ): string =>
    `${metricsUrl}/d/${value}/${value}?orgId=1&refresh=${refreshTimeoutMinutes}`;

  const renderContent = () => {
    if (isManager) {
      return (
        <>
          <ClusterReportLink
            to={makeDashboardLink('overview')}
            title="Cluster overview dashboard"
          />
          <ClusterReportLink
            to={makeDashboardLink('nodes')}
            title="Cluster nodes dashboard"
          />
          <ClusterReportLink
            to={makeDashboardLink('jobs')}
            title="Cluster jobs dashboard"
          />
          <ClusterReportLink
            to={makeDashboardLink('credits', '5m')}
            title="Cluster credits dashboard"
          />
          <ClusterReportLink
            to={makeDashboardLink('prices', '5m')}
            title="Cluster prices dashboard"
          />
        </>
      );
    }

    return (
      <>
        <ClusterReportLink
          to={makeDashboardLink('jobs')}
          title="Cluster jobs dashboard"
        />
        <ClusterReportLink
          to={makeDashboardLink('credits', '5m')}
          title="Cluster credits dashboard"
        />
      </>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Reports', 'Cluster Settings', '%c')} />
      <div className="flex flex-wrap gap-10">{renderContent()}</div>
    </>
  );
};
