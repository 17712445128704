import { useEffect, useRef, useState } from 'react';

import { JobNavigatorItem } from '@typings';
import { cn } from '@utils';

import { Button } from '@components';

type Navigator = {
  [k: string]: JobNavigatorItem;
};

type Props = {
  navigator: Navigator;
};

export const JobConstructorNavigator = ({ navigator }: Props) => {
  const sectionsRef = useRef<NodeListOf<Element>>();
  const [activeName, setActiveName] = useState(
    Object.values(navigator)[0].name,
  );

  useEffect(() => {
    const anchorsSelector = Object.values(navigator)
      .map(({ name }) => `#${name}`)
      .join(',');
    const sections = document.querySelectorAll(anchorsSelector);

    if (sections) {
      sectionsRef.current = sections;
    }
  }, [navigator]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionsRef.current;

      if (!sections) {
        return;
      }

      const element = Array.from(sections).find((element) => {
        const { top, bottom } = element.getBoundingClientRect();

        return bottom > 0 && top < window.innerHeight;
      });

      if (element) {
        const name = element.getAttribute('id')!;

        setActiveName(name);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigatorClick = (name: string) => {
    const SCROLL_TO_TIMEOUT = 450;
    const HEADER_OFFSET = -200;
    const top =
      document.getElementById(name)!.getBoundingClientRect().top +
      window.pageYOffset +
      HEADER_OFFSET;

    window.scrollTo({ top, behavior: 'smooth' });

    setTimeout(() => {
      setActiveName(name);
    }, SCROLL_TO_TIMEOUT);
  };

  const makeNavigatorItem = (
    { title, name }: JobNavigatorItem,
    index: number,
  ) => {
    const isActive = name === activeName;

    return (
      <Button
        theme
        key={name}
        className={cn(
          'group flex items-center gap-3 text-neural-04 transition-colors hover:text-black',
          { '!text-black': isActive },
        )}
        onClick={() => handleNavigatorClick(name)}
      >
        <span
          className={cn(
            'flex h-9 w-9 items-center justify-center rounded-full bg-neural-02 font-600 transition-colors',
            { '!bg-primary !text-white': isActive },
          )}
        >
          {index + 1}
        </span>
        <span className="text-h6">{title}</span>
      </Button>
    );
  };

  return (
    <div className="relative">
      <div className="sticky left-10 top-48 flex flex-col gap-10">
        {Object.values(navigator).map(makeNavigatorItem)}
      </div>
    </div>
  );
};
