import clsx from 'clsx';

import { PATH } from '@constants';
import { configSelector, contextNamesSelector } from '@selectors';
import { useContextParamsTracker, useSelector } from '@hooks';
import { getSlots } from '@utils';

import { Button, Link, Modal, Render, Slot, Theme } from '@components';
import { CreateProjectModal } from '@components/Modals';
import { UserPanel } from '@components/UserPanel';

import { LayoutContent } from './LayoutContent';
import { LayoutContentDrawer } from './LayoutContentDrawer';
import { LayoutEmptyContent } from './LayoutEmptyContent';
import { LayoutOnboarding } from './LayoutOnboarding';
import { LayoutSidebar } from './LayoutSidebar';

type Props = {
  className?: string;
  /**
   * Disables layout sidebar, projects, content header
   */
  showFeatures?: boolean;
  isProjectReffered?: boolean;
  title?: string;
  projectContextTracker?: boolean;
  clusterContextTracker?: boolean;
  children?: React.ReactNode;
};

export const Layout = ({
  className,
  showFeatures = true,
  isProjectReffered = true,
  title,
  projectContextTracker = true,
  clusterContextTracker = true,
  children,
}: Props) => {
  const { projects = [] } = useSelector(configSelector);
  const { organizationName, clusterName: clusterNameContext } =
    useSelector(contextNamesSelector);

  useContextParamsTracker({
    project: projectContextTracker,
    cluster: clusterContextTracker,
  });

  const { content, ...slots } = getSlots<'header'>(children);
  const projectsExist =
    projects.filter(
      ({ orgName, clusterName }) =>
        organizationName === orgName && clusterName === clusterNameContext,
    ).length > 0;

  return (
    <div className="relative flex h-full flex-1 flex-col pl-[280px] pt-20 font-inter text-body">
      <div className="fixed inset-x-0 top-0 z-30 flex h-20 items-center justify-between bg-rebecca px-8 py-5">
        <div className="mr-10 flex w-[248px] items-center justify-between">
          <Link theme to={PATH.HOME} className="py-2">
            <Theme.Logo className="h-10 w-auto max-w-[220px] text-white transition-colors" />
          </Link>
          <div className="h-12 w-px bg-white/25" />
        </div>
        <div className="flex min-w-0 flex-1 items-center justify-between">
          <Slot slot={slots.header}>
            <h4 className="truncate text-h4 text-white" title={title}>
              {title}
            </h4>
          </Slot>
          <UserPanel />
        </div>
      </div>
      <div className={clsx('relative flex flex-1 bg-background', className)}>
        <LayoutSidebar showFeatures={showFeatures} />

        {/**
         * User must have a project to use the platform so far if `isProjectReffered` is truthy
         *
         * Unlike `showFeatures` for manual disabling features for custom content
         * `projectsExists` is part of authorized layout
         */}
        <Render if={!isProjectReffered || !showFeatures || projectsExist}>
          {content}
        </Render>
        <Render if={isProjectReffered && showFeatures && !projectsExist}>
          <div className="flex w-full flex-col items-center justify-center gap-4">
            <p className="text-center text-h5">
              Create project for
              <span className="space-both text-primary">
                {organizationName || 'no'}
              </span>
              organization
            </p>
            <Modal content={<CreateProjectModal />}>
              <Button>Create project</Button>
            </Modal>
          </div>
        </Render>
      </div>
    </div>
  );
};

Layout.Content = LayoutContent;
Layout.EmptyContent = LayoutEmptyContent;
Layout.ContentDrawer = LayoutContentDrawer;
Layout.Onboarding = LayoutOnboarding;
