type Props = {
  number: number;
  title: string;
  name: string;
  children?: React.ReactNode;
};

export const JobConstructorSection = ({
  number,
  title,
  name,
  children,
}: Props) => {
  return (
    <div className="flex flex-col gap-6">
      <div id={name} className="flex items-center gap-3">
        <span className="flex h-9 w-9 items-center justify-center rounded-full bg-primary text-white">
          {number}
        </span>
        <p className="text-h4">{title}</p>
      </div>
      {children}
    </div>
  );
};
