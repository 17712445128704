import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { cn } from '@utils';

import { Button, Field, Icon, Modal, Render } from '@components';

import { AppNodePoolsModal } from '../Modals';

type Props = {
  error?: string;
};

export const AppNodePoolField = ({ error }: Props) => {
  const { register, watch, setValue } = useFormContext();

  const nodePoolName: string = watch('nodePoolName');

  useEffect(() => {
    register('nodePoolName');
  }, [register]);

  const handleChange = (name: string) => {
    setValue('nodePoolName', name);
  };

  return (
    <>
      <Modal content={<AppNodePoolsModal onApply={handleChange} />}>
        <Button
          theme
          className={cn(
            'relative flex h-14 items-center gap-2.5 rounded-lg border border-transparent bg-neural-01 px-4 py-2 transition-colors hover:border-neural-03',
            {
              '!border-error': !!error,
            },
          )}
        >
          <Icon icon={faMicrochip} className="text-[22px]" />
          <div className="text-left">
            <p
              className={clsx('required-label text-neural-04', {
                'text-caption': !!nodePoolName,
              })}
            >
              Node Pool
            </p>
            <Render if={nodePoolName}>
              <p>{nodePoolName}</p>
            </Render>
          </div>
          <Icon
            icon={faCaretDown}
            className="pointer-events-none absolute inset-y-0 right-4 m-auto text-neural-04"
          />
        </Button>
      </Modal>
      <Field.Error variant="static" className="-mt-4">
        {error}
      </Field.Error>
    </>
  );
};
