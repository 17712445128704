import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';

import { AnyObject, ModalProps } from '@typings';
import { isNumber, isString, noop, path, tv, VariantProps } from '@utils';

import { Button, Icon, Link, Modal } from '@components';

dayjs.extend(relativeTime);

const variants = tv({
  base: 'text-black capitalize',
  variants: {
    status: {
      pending: 'text-warning',
      succeeded: 'text-success',
      failed: 'text-error',
      cancelled: 'text-error',
      running: 'text-success',
      suspended: 'text-warning',
      cached: 'text-success-light',
    },
  },
});

type Props = ModalProps &
  VariantProps<typeof variants> & {
    jobId: string | null;
    outputs: AnyObject;
    createdAt: string;
    label?: string;
  };

export const BakeModal = ({
  label,
  jobId,
  status,
  createdAt,
  outputs,
  closeModal = noop,
}: Props) => {
  const jobUrl = jobId ? path.job(jobId) : '';

  const makeOutput = ([name, value]: [string, unknown]) => {
    const formattedValue = isString(value) || isNumber(value) ? value : '';

    return (
      <div key={name} className="flex">
        <p className="flex-1">name</p>
        <p className="flex-1">{formattedValue}</p>
      </div>
    );
  };

  return (
    <Modal.Content title={label} className="w-[520px]">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <p className="text-footnote text-neural-03">Job</p>
          <Link
            theme
            external
            blank
            className="flex items-center gap-2 underline"
            to={jobUrl}
          >
            <Icon icon={faBolt} className="text-[22px] text-neural-03" />
            {jobId}
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-footnote text-neural-03">Status</p>
          <div className="flex">
            <p className="flex-1">{dayjs(createdAt).fromNow()}</p>
            <p className={variants({ className: 'flex-1 capitalize', status })}>
              {status}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-footnote text-neural-03">Outputs</p>
            {Object.entries(outputs).map(makeOutput).filter(Boolean)}
          </div>
        </div>
      </div>

      <Modal.Footer>
        <Button variant="secondary" className="px-14" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
